import { CSSTransition } from 'react-transition-group'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, Autoplay, Navigation } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/autoplay'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import './Home.css'
import { useEffect, useRef, useState } from 'react'
import { throttle } from 'lodash'
import ReactPlayer from 'react-player'
import { ReactComponent as Logo } from '../../resource/image/logo.svg'
import { ReactComponent as AppleLogo } from '../../resource/image/apple_logo.svg'
import { ReactComponent as AppleLogo2 } from '../../resource/image/apple_logo_2.svg'
import { ReactComponent as GooglePlay } from '../../resource/image/google_play.svg'
import { ReactComponent as Prev } from '../../resource/image/prev.svg'
import { ReactComponent as Next } from '../../resource/image/next.svg'
import { ReactComponent as MobileChevronRight } from '../../resource/image/mobile_chevron_right.svg'
import home1 from '../../resource/image/home_1.png'
import home2 from '../../resource/image/home_2.gif'
import home3 from '../../resource/image/home_3.png'
import home221 from '../../resource/image/home_2_2_1.png'
import home222 from '../../resource/image/home_2_2_2.png'
import home223 from '../../resource/image/home_2_2_3.png'
import home224 from '../../resource/image/home_2_2_4.png'
import home225 from '../../resource/image/home_2_2_5.png'
import home226 from '../../resource/image/home_2_2_6.png'
import home227 from '../../resource/image/home_2_2_7.png'
import home4 from '../../resource/image/home_4.png'
import home5 from '../../resource/image/home_5.png'
import home6 from '../../resource/image/home_6.png'
import home7 from '../../resource/image/home_7.png'
import home8 from '../../resource/image/home_8.png'
import home9 from '../../resource/image/home_9.png'
import home11 from '../../resource/image/home_11.png'
import dduiRocket from '../../resource/image/ddui_rocket.png'
import review1 from '../../resource/image/review_1.png'
import review2 from '../../resource/image/review_2.png'
import review3 from '../../resource/image/review_3.png'
import review4 from '../../resource/image/review_4.png'
import books from '../../resource/image/books.png'
import repeat from '../../resource/image/repeat.png'
import routine from '../../resource/image/routine.png'
import dailylearningVideo from '../../resource/video/dailylearning.mp4'
import grade1ContentVideo from '../../resource/video/grade1_content.mp4'
import grade2ContentVideo from '../../resource/video/grade2_content.mp4'
import grade3ContentVideo from '../../resource/video/grade3_content.mp4'
import grade4ContentVideo from '../../resource/video/grade4_content.mp4'
import grade5ContentVideo from '../../resource/video/grade5_content.mp4'
import grade6ContentVideo from '../../resource/video/grade6_content.mp4'
import brain from '../../resource/image/brain.png'
import trophy from '../../resource/image/trophy.png'
import cloth from '../../resource/image/cloth.png'
import home121 from '../../resource/image/home_12_1.png'
import home122 from '../../resource/image/home_12_2.png'
import home123 from '../../resource/image/home_12_3.png'
import home124 from '../../resource/image/home_12_4.png'
import home125 from '../../resource/image/home_12_5.png'
import home126 from '../../resource/image/home_12_6.png'
import home131 from '../../resource/image/home_13_1.png'
import home132 from '../../resource/image/home_13_2.png'
import home133 from '../../resource/image/home_13_3.png'
import home134 from '../../resource/image/home_13_4.png'
import home135 from '../../resource/image/home_13_5.png'
import home136 from '../../resource/image/home_13_6.png'
import home141 from '../../resource/image/home_14_1.png'
import home142 from '../../resource/image/home_14_2.png'
import home15 from '../../resource/image/home_15.png'
import home16 from '../../resource/image/home_16.png'
import homeBg1 from '../../resource/image/home_bg_1.png'
import homeMobileBg1 from '../../resource/image/home_mobile_bg_1.png'
import homeBg2 from '../../resource/image/home_bg_2.png'
import homeMobileBg2 from '../../resource/image/home_mobile_bg_2.png'
import homeBg3 from '../../resource/image/home_bg_3.png'
import homeBg4 from '../../resource/image/home_bg_4.png'
import homeBg5 from '../../resource/image/home_bg_5.png'
import homeBg6 from '../../resource/image/home_bg_6.png'
import homeBg8 from '../../resource/image/home_bg_8.png'
import homeBg9 from '../../resource/image/home_bg_9.png'
import mobilePhoneBorder from '../../resource/image/mobile_phone_border.png'
import mobileOneproparentApp from '../../resource/image/mobile_oneproparent_app.png'
import mobileAwardEfC from '../../resource/image/mobile_award_ef_c.png'
import mobileAwardEfL from '../../resource/image/mobile_award_ef_l.png'
import mobileAwardEfR from '../../resource/image/mobile_award_ef_r.png'
import mobileAwardTrophy from '../../resource/image/mobile_award_trophy.png'
import mobileAward1 from '../../resource/image/mobile_award_1.png'
import mobileAward2 from '../../resource/image/mobile_award_2.png'
import mobileAward3 from '../../resource/image/mobile_award_3.png'
import mobilePlanetTop from '../../resource/image/mobile_planet_top.png'
import mobileHomelearnLogo from '../../resource/image/mobile_homelearn_logo.png'
import mobileNumber from '../../resource/image/mobile_number.png'
import mobileUfo from '../../resource/image/mobile_ufo.png'
import mobileOneprofriends from '../../resource/image/mobile_oneprofriends.png'
import mobilePlanet from '../../resource/image/mobile_planet.png'
import mobileNote from '../../resource/image/mobile_note.png'
import mobileIncrease from '../../resource/image/mobile_increase.png'
import mobileRocketDdui from '../../resource/image/mobile_rocket_ddui.png'
import mobileReviewBg from '../../resource/image/mobile_review_bg.png'
import mobileSecretBg from '../../resource/image/mobile_secret_bg.png'
import mobileBrain from '../../resource/image/mobile_brain.png'
import mobileTrophy from '../../resource/image/mobile_trophy.png'
import mobileCloth from '../../resource/image/mobile_cloth.png'
import mobileManageBg from '../../resource/image/mobile_manage_bg.png'
import mobileAppIcons from '../../resource/image/mobile_app_icons.png'
import mobileOneproParentsApp from '../../resource/image/mobile_oneproparents_app.png'
import mobileHomeBg9 from '../../resource/image/mobile_home_bg_9.png'
import mobileMomNChild from '../../resource/image/mobile_momnchild.png'
import Footer from '../../component/Footer'
import { useNavigate } from 'react-router-dom'
import ScrollToTopButton from '../../component/ScrollToTopButton'

const Home = () => {
  const navigate = useNavigate()
  const [showHomeContent1, setShowHomeContent1] = useState(false)
  const [showHomeContent2, setShowHomeContent2] = useState(false)
  const [showHomeContent3, setShowHomeContent3] = useState(false)
  const [showHomeContent4, setShowHomeContent4] = useState(false)
  const [showHomeContent5, setShowHomeContent5] = useState(false)
  const [showHomeContent6, setShowHomeContent6] = useState(false)
  const [showHomeContent7, setShowHomeContent7] = useState(false)
  const [showHomeContent8, setShowHomeContent8] = useState(false)
  const [showHomeContent9, setShowHomeContent9] = useState(false)
  const [showHomeContent10, setShowHomeContent10] = useState(false)
  const [showHomeContent11, setShowHomeContent11] = useState(false)
  const [showHomeContent12, setShowHomeContent12] = useState(false)
  const homeContent2 = useRef(null)
  const homeContent3 = useRef(null)
  const homeContent4 = useRef(null)
  const homeContent5 = useRef(null)
  const homeContent6 = useRef(null)
  const homeContent7 = useRef(null)
  const homeContent8 = useRef(null)
  const homeContent9 = useRef(null)
  const homeContent10 = useRef(null)
  const homeContent11 = useRef(null)
  const homeContent12 = useRef(null)
  const swiperRef = useRef(null)
  const swiperMobileRef = useRef(null)
  const infiniteRollingBanner1Ref = useRef(null)
  const infiniteRollingBanner2Ref = useRef(null)
  const infiniteRollingBanner3Ref = useRef(null)
  const infiniteRollingBanner4Ref = useRef(null)
  const infiniteRollingBanner5Ref = useRef(null)
  const grades = ['1학년', '2학년', '3학년', '4학년', '5학년', '6학년',]
  const [isDragging, setIsDragging] = useState(false)

  const handleDragStart = () => {
    setIsDragging(true)
  }

  const handleDragEnd = () => {
    setIsDragging(false)
  }

  const controlScroll = () => {
    let halfInternalHeight = window.innerHeight / 2
    const homeContent2Scroll = homeContent2.current.getBoundingClientRect()
    setShowHomeContent2(homeContent2Scroll.top <= halfInternalHeight)
    const homeContent3Scroll = homeContent3.current.getBoundingClientRect()
    setShowHomeContent3(homeContent3Scroll.top <= halfInternalHeight)
    const homeContent4Scroll = homeContent4.current.getBoundingClientRect()
    setShowHomeContent4(homeContent4Scroll.top <= halfInternalHeight)
    const homeContent5Scroll = homeContent5.current.getBoundingClientRect()
    setShowHomeContent5(homeContent5Scroll.top <= halfInternalHeight)
    const homeContent6Scroll = homeContent6.current.getBoundingClientRect()
    setShowHomeContent6(homeContent6Scroll.top <= halfInternalHeight)
    const homeContent7Scroll = homeContent7.current.getBoundingClientRect()
    setShowHomeContent7(homeContent7Scroll.top <= halfInternalHeight)
    const homeContent8Scroll = homeContent8.current.getBoundingClientRect()
    setShowHomeContent8(homeContent8Scroll.top <= halfInternalHeight)
    const homeContent9Scroll = homeContent9.current.getBoundingClientRect()
    setShowHomeContent9(homeContent9Scroll.top <= halfInternalHeight)
    const homeContent10Scroll = homeContent10.current.getBoundingClientRect()
    setShowHomeContent10(homeContent10Scroll.top <= halfInternalHeight)
    const homeContent11Scroll = homeContent11.current.getBoundingClientRect()
    setShowHomeContent11(homeContent11Scroll.top <= halfInternalHeight)
    const homeContent12Scroll = homeContent12.current.getBoundingClientRect()
    setShowHomeContent12(homeContent12Scroll.top <= halfInternalHeight)
  }

  const handleScroll = throttle(controlScroll, 200)

  const handleResize = throttle(controlScroll, 200)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', handleScroll)
      // cleanup function
      return () => {
        window.removeEventListener('scroll', handleScroll)
      }
    }
  }, [])

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    // 컴포넌트가 언마운트될 때 이벤트 리스너를 제거
    return () => window.removeEventListener('resize', handleResize)
  }, []) // 빈 의존성 배열을 사용하여 마운트 시에만 이벤트 리스너를 추가

  useEffect(() => {
    setShowHomeContent1(true)

    // 무한 롤링 배너 설정
    // autoplay로 설정된 슬라이드가 시작과 끝부분은 동일한 속도이지만 화면의 정면으로 가까워지면 점차 느려진다.
    // react swiper는 이것을 해결하는 옵션을 가지고 있지 않은 것으로 보임.
    // 그래서 직접 .swiper-wrapper 클래스를 물고 있는 엘리먼트를 가져와서 스타일을 재정의한다.
    if (infiniteRollingBanner1Ref.current) {
      const swiperWrapper = infiniteRollingBanner1Ref.current.querySelector('.swiper-wrapper')
      if (swiperWrapper) {
        swiperWrapper.style.transitionTimingFunction = 'linear'
      }
    }
    if (infiniteRollingBanner2Ref.current) {
      const swiperWrapper = infiniteRollingBanner2Ref.current.querySelector('.swiper-wrapper')
      if (swiperWrapper) {
        swiperWrapper.style.transitionTimingFunction = 'linear'
      }
    }
    if (infiniteRollingBanner3Ref.current) {
      const swiperWrapper = infiniteRollingBanner3Ref.current.querySelector('.swiper-wrapper')
      if (swiperWrapper) {
        swiperWrapper.style.transitionTimingFunction = 'linear'
      }
    }
    if (infiniteRollingBanner4Ref.current) {
      const swiperWrapper = infiniteRollingBanner4Ref.current.querySelector('.swiper-wrapper')
      if (swiperWrapper) {
        swiperWrapper.style.transitionTimingFunction = 'linear'
      }
    }
    if (infiniteRollingBanner5Ref.current) {
      const swiperWrapper = infiniteRollingBanner5Ref.current.querySelector('.swiper-wrapper')
      if (swiperWrapper) {
        swiperWrapper.style.transitionTimingFunction = 'linear'
      }
    }
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0) // 페이지 로드 시 스크롤을 최상단으로 이동
  }, [])

  return (
    <div className={'w-[100vw] overflow-hidden'}>
      <div className={'block sm:hidden'}>
        <ScrollToTopButton/>
      </div>
      <Swiper
        modules={[Pagination, Autoplay]}
        slidesPerView={1}
        pagination={{
          clickable: true,
          bulletClass: 'swiper-pagination-bullet custom-bullet', // 커스텀 클래스 추가
          bulletActiveClass: 'swiper-pagination-bullet-active custom-bullet-active', // 커스텀 클래스 추가
          el: '.custom-swiper-pagination-mainbanner', // 커스텀 페이지네이션
        }}
        // autoplay={{ delay: 4000, disableOnInteraction: false, pauseOnMouseEnter: true }}
        // onSlideChange={() => console.log('slide change')}
        // onSwiper={(swiper) => console.log(swiper)}
      >
        <SwiperSlide>
          <div className={'relative'}>
            <picture className={'relative'}>
              <source media="(min-width: 640px)" srcSet={homeBg1}/>
              <source media="(max-width: 639px)" srcSet={homeMobileBg1}/>
              <img src={homeBg1} alt={'home_bg_1'} className={'w-full'}/>
            </picture>
            <CSSTransition in={showHomeContent1} timeout={700} classNames={'home-content-1-1'} mountOnEnter unmountOnExit>
              <div className={'w-full absolute top-[30vw] sm:top-[8.542vw] flex justify-center items-center leading-none'}>
                <span className={'font-[wanjudaedunsanche-r] text-[11.667vw] sm:text-[3.646vw] text-[#fffb9f] drop-shadow-[0_4px_4px_rgba(0,0,0,0.4)]'}>7일의 기적</span>
              </div>
            </CSSTransition>
            <CSSTransition in={showHomeContent1} timeout={700} classNames={'home-content-1-1'} mountOnEnter unmountOnExit>
              <div className={'w-full absolute top-[44.444vw] sm:top-[12.812vw] flex justify-center items-center text-center leading-none'}>
                <span className={'font-[nanum-barun-gothic-b] text-[8.333vw] sm:text-[2.188vw] text-[#ffffff] drop-shadow-[0_4px_4px_rgba(0,0,0,0.4)]'}>1등 엄마의 연산 문제집</span>
              </div>
            </CSSTransition>
            <CSSTransition in={showHomeContent1} timeout={900} classNames={'home-content-1-2'} mountOnEnter unmountOnExit>
              <div className={'w-full absolute top-[62.5vw] sm:top-[17.344vw] flex justify-center'}>
                <img src={home2} alt="home_2" className={'w-[86.111vw] sm:w-[34.896vw]'}/>
              </div>
            </CSSTransition>
            <CSSTransition in={showHomeContent1} timeout={900} classNames={'home-content-1-2'} mountOnEnter unmountOnExit>
              <picture className={'w-full absolute top-[60vw] sm:top-[16.146vw] flex justify-center'}>
                <source media="(min-width: 640px)" srcSet={home1}/>
                <source media="(max-width: 639px)" srcSet={mobilePhoneBorder}/>
                <img src={mobilePhoneBorder} alt={'mobile_phone_border'} className={'w-full sm:w-[36.563vw]'}/>
              </picture>
            </CSSTransition>
            <a
              target={'_blank'}
              href={'https://apps.apple.com/kr/app/%EC%9D%BC%ED%94%84%EB%A1%9C%EC%97%B0%EC%82%B0-%EC%B4%88%EB%93%B1-%EC%88%98%ED%95%99-%EC%97%B0%EC%82%B0-%EB%AC%B8%EC%A0%9C%EC%A7%91/id1550930744'}
              className="absolute top-[120.833vw] sm:top-[39.479vw] left-[4.444vw] sm:left-[29.063vw] border-[0.556vw] sm:border-[0.104vw] w-[43.333vw] sm:w-[13.021vw] h-[14.722vw] sm:h-[3.542vw] rounded-[2.778vw] sm:rounded-[0.521vw] flex justify-center items-center cursor-pointer">
              <AppleLogo className={'w-[5.556vw] sm:w-[1.276vw]'}/>
              <span className="font-[nanum-barun-gothic-b] text-[5vw] sm:text-[1.25vw] text-[#ffffff] ml-[1.111vw] sm:ml-[0.521vw] leading-none">앱스토어</span>
            </a>
            <a
              target={'_blank'}
              href={'https://play.google.com/store/apps/details?id=com.Mathmaster.OneProMath&hl=ko&gl=US'}
              className="absolute top-[120.833vw] sm:top-[39.479vw] left-[52.222vw] sm:left-[43.75vw] border-[0.556vw] sm:border-[0.104vw] w-[43.333vw] sm:w-[13.021vw] h-[14.722vw] sm:h-[3.542vw] rounded-[2.778vw] sm:rounded-[0.521vw] flex justify-center items-center cursor-pointer">
              <GooglePlay className={'w-[5.556vw] sm:w-[1.359vw]'}/>
              <span className="font-[nanum-barun-gothic-b] text-[5vw] sm:text-[1.25vw] text-[#ffffff] ml-[1.111vw] sm:ml-[0.521vw] leading-none">구글플레이</span>
            </a>
            <div
              onClick={() => {
                navigate(`/pcversion`)
              }}
              className="absolute top-[138.889vw] sm:top-[39.479vw] left-[4.444vw] sm:left-[58.438vw] border-[0.556vw] sm:border-[0.104vw] w-[91.111vw] sm:w-[13.021vw] h-[14.722vw] sm:h-[3.542vw] rounded-[2.778vw] sm:rounded-[0.521vw] flex justify-center items-center cursor-pointer">
              <span className="font-[nanum-barun-gothic-b] text-[5vw] sm:text-[1.25vw] text-[#ffffff] leading-none">
                PC설치(Windows)
              </span>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#ffffff" className="w-[1.25vw] hidden sm:block">
                <path fillRule="evenodd" d="M16.28 11.47a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 0 1-1.06-1.06L14.69 12 7.72 5.03a.75.75 0 0 1 1.06-1.06l7.5 7.5Z" clipRule="evenodd"/>
              </svg>
              <MobileChevronRight className={'w-[2.667vw] h-[4.272vw] absolute top-[50%] -translate-y-1/2 right-[6.944vw] block sm:hidden'}/>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className={'relative'}>
            <picture className={'relative'}>
              <source media="(min-width: 640px)" srcSet={homeBg2}/>
              <source media="(max-width: 639px)" srcSet={homeMobileBg2}/>
              <img src={homeBg2} alt={'home_bg_2'} className={'w-full'}/>
            </picture>
            <div className={'absolute top-[23.611vw] sm:top-[15.26vw] left-[8.889vw] sm:left-[20.417vw]'}>
              <div className="flex items-end mb-[-2.188vw]">
                <span className="flex flex-col items-center">
                  <div className="bg-[#5C6DFF] w-[2.222vw] sm:w-[0.833vw] h-[2.222vw] sm:h-[0.833vw] rounded-full"></div>
                  <div className={'font-[wanjudaedunsanche-r] text-[#2046E4] text-[9.444vw] sm:text-[4.167vw]'}>1</div>
                </span>
                <span className="flex flex-col items-center">
                  <div className="bg-[#5C6DFF] w-[2.222vw] sm:w-[0.833vw] h-[2.222vw] sm:h-[0.833vw] rounded-full"></div>
                  <div className={'font-[wanjudaedunsanche-r] text-[#2046E4] text-[9.444vw] sm:text-[4.167vw]'}>등</div>
                </span>
                <span className={'font-[wanjudaedunsanche-r] text-[#2046E4] text-[9.444vw] sm:text-[4.167vw]'}>&nbsp;엄마</span>
                <span className={'font-[nanum-barun-gothic-b] text-[#4F4F4F] text-[6.667vw] sm:text-[3.125vw] pb-[1.667vw] sm:pb-[0.521vw]'}>의</span>
              </div>
            </div>
            <div className={'absolute top-[38.889vw] sm:top-[22.292vw] left-[8.889vw] sm:left-[20.417vw] font-[nanum-barun-gothic-b] text-[#4f4f4f] text-[6.667vw] sm:text-[3.125vw] leading-none'}>
              편리한 학습 관리법
            </div>
            <div className={'absolute top-[48.889vw] sm:top-[26.563vw] left-[8.889vw] sm:left-[20.417vw] font-[nanum-barun-gothic-b] text-[#868686] text-[4.444vw] sm:text-[1.406vw] leading-none'}>
              #일프로연산 학부모
            </div>
            <a
              target={'_blank'}
              href={'https://apps.apple.com/us/app/%EC%9D%BC%ED%94%84%EB%A1%9C%EC%97%B0%EC%82%B0-%ED%95%99%EB%B6%80%EB%AA%A8/id1661048032'}
              className="absolute top-[138.889vw] sm:top-[31.979vw] left-[4.444vw] sm:left-[20.417vw] border-[0.556vw] sm:border-[0.104vw] border-[#2046E4] w-[43.333vw] sm:w-[12.5vw] h-[14.722vw] sm:h-[3.542vw] rounded-[2.778vw] sm:rounded-[0.521vw] flex justify-center items-center cursor-pointer leading-none">
              <AppleLogo2 className={'w-[5.556vw] sm:w-[1.276vw]'}/>
              <span className="font-[nanum-barun-gothic-b] text-[5vw] sm:text-[1.25vw] text-[#2046E4] ml-[1.111vw] sm:ml-[0.521vw]">앱스토어</span>
            </a>
            <a
              target={'_blank'}
              href={'https://play.google.com/store/apps/details?id=com.mathmaster.oneproparents&hl=en_US'}
              className="absolute top-[138.889vw] sm:top-[31.979vw] left-[52.222vw] sm:left-[35.104vw] border-[0.556vw] sm:border-[0.104vw] border-[#2046E4] w-[43.333vw] sm:w-[12.5vw] h-[14.722vw] sm:h-[3.542vw] rounded-[2.778vw] sm:rounded-[0.521vw] flex justify-center items-center cursor-pointer leading-none z-[1]">
              <GooglePlay className={'w-[5.556vw] sm:w-[1.354vw]'}/>
              <span className="font-[nanum-barun-gothic-b] text-[5vw] sm:text-[1.25vw] text-[#2046E4] ml-[1.111vw] sm:ml-[0.521vw]">구글플레이</span>
            </a>
            <picture className={'absolute top-[53.056vw] sm:top-[9.896vw] left-0 sm:left-[39.375vw]'}>
              <source media="(min-width: 640px)" srcSet={home3}/>
              <source media="(max-width: 639px)" srcSet={mobileOneproparentApp}/>
              <img src={home3} alt={'home_3'} className={'w-[98.333vw] sm:w-[41.875vw]'}/>
            </picture>
          </div>
        </SwiperSlide>
        <div className="custom-swiper-pagination-mainbanner"></div>
      </Swiper>
      <div className={'w-full h-[213.889vw] sm:h-[52.135vw] relative'} ref={homeContent2}>
        <CSSTransition in={showHomeContent2} timeout={700} classNames={'home-content-2-1'} mountOnEnter unmountOnExit>
          <picture className={'w-full sm:w-auto absolute top-[41.389vw] sm:top-[4.167vw] left-[50%] -translate-x-1/2'}>
            <source media="(min-width: 640px)" srcSet={home225}/>
            <source media="(max-width: 639px)" srcSet={mobileAwardEfC}/>
            <img src={home225} alt="home_2_2_5" className={'w-full sm:w-[42.396vw]'}/>
          </picture>
        </CSSTransition>
        <CSSTransition in={showHomeContent2} timeout={700} classNames={'home-content-2-2'} mountOnEnter unmountOnExit>
          <div className="w-full absolute top-[35.556vw] sm:top-[5.313vw] flex justify-center font-[nanum-square-round-eb] text-[7.778vw] sm:text-[2.604vw] leading-none">
            <span className={'text-[#2046E4]'}>결과로 입증된</span>&nbsp;일프로연산
          </div>
        </CSSTransition>
        <CSSTransition in={showHomeContent2} timeout={700} classNames={'home-content-2-3'} mountOnEnter unmountOnExit>
          <picture className={'absolute top-[64.722vw] sm:top-[14.427vw] left-[50%] -translate-x-1/2'}>
            <source media="(min-width: 640px)" srcSet={home224}/>
            <source media="(max-width: 639px)" srcSet={mobileAwardTrophy}/>
            <img src={home224} alt="home_2_2_4" className={'w-[36.667vw] sm:w-[9.479vw]'}/>
          </picture>
        </CSSTransition>
        <CSSTransition in={showHomeContent2} timeout={700} classNames={'home-content-2-4'} mountOnEnter unmountOnExit>
          <picture className={'absolute top-[76.667vw] sm:top-[9.531vw] left-[4.167vw] sm:left-[28.333vw]'}>
            <source media="(min-width: 640px)" srcSet={home226}/>
            <source media="(max-width: 639px)" srcSet={mobileAwardEfL}/>
            <img src={home226} alt="home_2_2_6" className={'w-[37.5vw] sm:w-[10.208vw]'}/>
          </picture>
        </CSSTransition>
        <CSSTransition in={showHomeContent2} timeout={700} classNames={'home-content-2-5'} mountOnEnter unmountOnExit>
          <picture className={'absolute top-[76.667vw] sm:top-[11.25vw] right-[2.222vw] sm:right-[28.49vw]'}>
            <source media="(min-width: 640px)" srcSet={home227}/>
            <source media="(max-width: 639px)" srcSet={mobileAwardEfR}/>
            <img src={home227} alt="home_2_2_7" className={'w-[31.944vw] sm:w-[7.708vw]'}/>
          </picture>
        </CSSTransition>
        <div className={'block sm:hidden w-full absolute top-[100vw]'}>
          <Swiper
            onSwiper={(swiper) => (swiperMobileRef.current = swiper)}
            onSliderMove={handleDragStart}
            onTouchEnd={handleDragEnd}
            onMouseUp={handleDragEnd}
            modules={[Pagination, Autoplay, Navigation]}
            slidesPerView={1}
            loop={true}
            // autoplay={{ delay: 4000, disableOnInteraction: false, pauseOnMouseEnter: true }}
          >
            <SwiperSlide style={{ display: 'flex', justifyContent: 'center', paddingTop: '4.167vw', paddingBottom: '4.167vw' }}>
              <div className={'w-[91.111vw] h-[91.944vw] relative rounded-[11.111vw] shadow-[0_1.111vw_2.778vw_1.389vw_rgba(0,0,0,0.1)] bg-gradient-to-b from-[#ffffff] to-[#F6F9FF]'}>
                <img src={mobileAward1} alt={'mobile_award_1'} className={'w-[57.778vw] absolute top-[6.111vw] left-[16.667vw]'}/>
                <div className={'w-full absolute top-[63.056vw] font-[nanum-square-round-eb] text-[6.667vw] text-center leading-snug'}>
                  학부모가 뽑은<br/>
                  교육 브랜드 대상
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide style={{ display: 'flex', justifyContent: 'center', paddingTop: '4.167vw', paddingBottom: '4.167vw' }}>
              <div className={'w-[91.111vw] h-[91.944vw] relative rounded-[11.111vw] shadow-[0_1.111vw_2.778vw_1.389vw_rgba(0,0,0,0.1)] bg-gradient-to-b from-[#ffffff] to-[#F6F9FF]'}>
                <img src={mobileAward2} alt={'mobile_award_2'} className={'w-[57.778vw] absolute top-[6.111vw] left-[16.667vw]'}/>
                <div className={'w-full absolute top-[63.056vw] font-[nanum-square-round-eb] text-[6.667vw] text-center leading-snug'}>
                  대한민국 교육 브랜드<br/>
                  2회 연속 수상
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide style={{ display: 'flex', justifyContent: 'center', paddingTop: '4.167vw', paddingBottom: '4.167vw' }}>
              <div className={'w-[91.111vw] h-[91.944vw] relative rounded-[11.111vw] shadow-[0_1.111vw_2.778vw_1.389vw_rgba(0,0,0,0.1)] bg-gradient-to-b from-[#ffffff] to-[#F6F9FF]'}>
                <img src={mobileAward3} alt={'mobile_award_3'} className={'w-[57.778vw] absolute top-[6.111vw] left-[16.667vw]'}/>
                <div className={'w-full absolute top-[63.056vw] font-[nanum-square-round-eb] text-[6.667vw] text-center leading-snug'}>
                  에듀 플러스 어워드<br/>
                  수학어플 대상
                </div>
              </div>
            </SwiperSlide>
            {/* 커스텀 네비게이션 버튼 */}
            <button
              onClick={() => swiperMobileRef.current.slidePrev()}
              className={`absolute top-[43.333vw] left-[2.222vw] z-[1] w-[20vw] h-[20vw] rounded-full flex justify-center items-center shadow-[0_1.389vw_5.556vw_0_rgba(0,0,0,0.1)] bg-[#ffffff] cursor-pointer ${isDragging ? 'fade-out' : 'fade-in'}`}
            >
              <Prev className={'w-[5.556vw]'}/>
            </button>
            <button
              onClick={() => swiperMobileRef.current.slideNext()}
              className={`absolute top-[43.333vw] right-[2.222vw] z-[1] w-[20vw] h-[20vw] rounded-full flex justify-center items-center shadow-[0_1.389vw_5.556vw_0_rgba(0,0,0,0.1)] bg-[#ffffff] cursor-pointer ${isDragging ? 'fade-out' : 'fade-in'}`}
            >
              <Next className={'w-[5.556vw]'}/>
            </button>
          </Swiper>
        </div>
        <div className={'hidden sm:block'}>
          <CSSTransition in={showHomeContent2} timeout={900} classNames={'home-content-2-6'} mountOnEnter unmountOnExit>
            <div
              className={'w-[20vw] h-[20.625vw] absolute top-[30.938vw] left-[20vw] shadow-[0_0_16px_2px_rgba(0,0,0,0.2)] rounded-[2.083vw] flex justify-center items-center bg-gradient-to-b from-[#ffffff] to-[#F6F9FF]'}>
              <div>
                <div className={'flex justify-center'}>
                  <img src={home221} alt="home_2_2_1" className={'w-[11.615vw]'}/>
                </div>
                <div className={'font-[nanum-square-round-eb] text-[1.823vw] text-center mt-[1.042vw] leading-snug'}>
                  에듀플러스 어워드<br/>
                  수학어플 대상
                </div>
              </div>
            </div>
          </CSSTransition>
        </div>
        <div className={'hidden sm:block'}>
          <CSSTransition in={showHomeContent2} timeout={900} classNames={'home-content-2-6'} mountOnEnter unmountOnExit>
            <div
              className={'w-[20vw] h-[20.625vw] absolute top-[30.938vw] left-[60vw] shadow-[0_0_16px_2px_rgba(0,0,0,0.2)] rounded-[2.083vw] flex justify-center items-center bg-gradient-to-b from-[#ffffff] to-[#F6F9FF]'}>
              <div>
                <div className={'flex justify-center'}>
                  <img src={home223} alt="home_2_2_3" className={'w-[11.615vw]'}/>
                </div>
                <div className={'font-[nanum-square-round-eb] text-[1.823vw] text-center mt-[1.042vw] leading-snug'}>
                  대한민국 교육 브랜드<br/>
                  2회 연속 수상
                </div>
              </div>
            </div>
          </CSSTransition>
        </div>
        <div className={'hidden sm:block'}>
          <CSSTransition in={showHomeContent2} timeout={1100} classNames={'home-content-2-7'} mountOnEnter unmountOnExit>
            <div
              className={'w-[22.708vw] h-[23.438vw] absolute top-[24.635vw] left-[50%] -translate-x-1/2 shadow-[0_0_16px_2px_rgba(0,0,0,0.2)] rounded-[2.083vw] flex justify-center items-center bg-gradient-to-b from-[#ffffff] to-[#F6F9FF]'}>
              <div>
                <div className={'flex justify-center'}>
                  <img src={home222} alt="home_2_2_2" className={'w-[13.125vw]'}/>
                </div>
                <div className={'font-[nanum-square-round-eb] text-[1.979vw] text-center mt-[1.042vw] leading-snug'}>
                  학부모가 뽑은<br/>
                  교육 브랜드 대상
                </div>
              </div>
            </div>
          </CSSTransition>
        </div>
      </div>
      <div className={'w-full h-[132.222vw] sm:h-[46.615vw] relative'} ref={homeContent3}>
        <picture className={'w-full absolute bottom-0'}>
          <source media="(min-width: 640px)" srcSet={homeBg3}/>
          <source media="(max-width: 639px)" srcSet={mobilePlanetTop}/>
          <img src={homeBg3} alt="home_bg_3" className={'w-full'}/>
        </picture>
        <CSSTransition in={showHomeContent3} timeout={700} classNames={'home-content-3-1'} mountOnEnter unmountOnExit>
          <div className={'w-full absolute top-0 left-[50%] -translate-x-1/2 leading-none'}>
            <picture className={'flex justify-center'}>
              <source media="(min-width: 640px)" srcSet={home4}/>
              <source media="(max-width: 639px)" srcSet={mobileHomelearnLogo}/>
              <img src={home4} alt="home_4" className={'w-[90.833vw] sm:w-[37.76vw]'}/>
            </picture>
            <div className={'flex justify-center items-center font-[nanum-square-round-eb] text-[6.667vw] sm:text-[2.292vw] mt-0 sm:mt-[2.188vw]'}>아이스크림 홈런과 함께 합니다.</div>
            <div className={'flex justify-center items-center font-[nanum-square-round-eb] text-[4.167vw] sm:text-[0.938vw] mt-[4.167vw] sm:mt-[1.042vw] text-[#aeaeae]'}>(학습컨텐츠 수록)</div>
          </div>
        </CSSTransition>
        <CSSTransition in={showHomeContent3} timeout={900} classNames={'home-content-3-2'} mountOnEnter unmountOnExit>
          <picture className={'w-full absolute top-auto sm:top-[22.552vw] bottom-[-32.778vw] sm:bottom-auto flex justify-center z-[1]'}>
            <source media="(min-width: 640px)" srcSet={home5}/>
            <source media="(max-width: 639px)" srcSet={mobileOneprofriends}/>
            <img src={home5} alt="home_5" className={'w-[87.778vw] sm:w-[59.167vw]'}/>
          </picture>
        </CSSTransition>
        <CSSTransition in={showHomeContent3} timeout={900} classNames={'home-content-3-2'} mountOnEnter unmountOnExit>
          <picture className={'absolute top-[101.944vw] sm:top-[16.979vw] left-[8.056vw] sm:left-[3.333vw] animate-wiggle'}>
            <source media="(min-width: 640px)" srcSet={home6}/>
            <source media="(max-width: 639px)" srcSet={mobileNumber}/>
            <img src={home6} alt="home_6" className={'w-[26.111vw] sm:w-[15.521vw]'}/>
          </picture>
        </CSSTransition>
        <CSSTransition in={showHomeContent3} timeout={900} classNames={'home-content-3-2'} mountOnEnter unmountOnExit>
          <picture className={'absolute top-[78.056vw] sm:top-[7.969vw] right-[7.222vw] sm:right-[4.167vw] animate-wiggle'}>
            <source media="(min-width: 640px)" srcSet={home7}/>
            <source media="(max-width: 639px)" srcSet={mobileUfo}/>
            <img src={home7} alt="home_7" className={'w-[59.444vw] sm:w-[27.292vw]'}/>
          </picture>
        </CSSTransition>
      </div>
      <div className={'relative'} ref={homeContent4}>
        <picture className={'w-full relative'}>
          <source media="(min-width: 640px)" srcSet={homeBg4}/>
          <source media="(max-width: 639px)" srcSet={mobilePlanet}/>
          <img src={homeBg4} alt="home_bg_4" className={'w-full'}/>
        </picture>
        <CSSTransition in={showHomeContent4} timeout={700} classNames={'home-content-4-1'} mountOnEnter unmountOnExit>
          <div className={'w-full absolute top-[60.833vw] sm:top-[4.531vw] flex justify-center'}>
            <div className={'hidden sm:block font-[nanum-square-round-eb] text-[2.604vw] leading-none'}>
              <span className={'text-[#2046E4]'}>10명 중에 9명</span>이 느낀 놀라운 성적향샹
            </div>
            <div className={'block sm:hidden font-[nanum-square-round-eb] text-[7.778vw] text-center leading-snug'}>
              <span className={'text-[#2046E4]'}>10명 중에 9명</span>이<br/>
              느낀 놀라운 성적향샹
            </div>
          </div>
        </CSSTransition>
        <CSSTransition in={showHomeContent4} timeout={700} classNames={'home-content-4-1'} mountOnEnter unmountOnExit>
          <div className={'w-full absolute top-[88.056vw] sm:top-[9.219vw] flex justify-center font-[pretendard-m] text-[5vw] sm:text-[1.823vw] leading-none'}>
            7일 이내 성적향상을 경험한 학습자 99.3%
          </div>
        </CSSTransition>
        <picture className={'w-full absolute top-[106.667vw] sm:top-[13.958vw] flex justify-center'}>
          <source media="(min-width: 640px)" srcSet={home8}/>
          <source media="(max-width: 639px)" srcSet={mobileNote}/>
          <img src={home8} alt="home_8" className={'w-[91.111vw] sm:w-[62.5vw]'}/>
        </picture>
        <CSSTransition in={showHomeContent4} timeout={900} classNames={'home-content-4-2'} mountOnEnter unmountOnExit>
          <picture className={'w-full absolute top-[134.722vw] sm:top-[24.271vw] flex justify-center'}>
            <source media="(min-width: 640px)" srcSet={home9}/>
            <source media="(max-width: 639px)" srcSet={mobileIncrease}/>
            <img src={home9} alt="home_9" className={'w-[83.889vw] sm:w-[55.208vw]'}/>
          </picture>
        </CSSTransition>
        <CSSTransition in={showHomeContent4} timeout={900} classNames={'home-content-4-3'} mountOnEnter unmountOnExit>
          <picture className={'w-full absolute top-[127.778vw] sm:top-[23.385vw] flex justify-center'}>
            <source media="(min-width: 640px)" srcSet={dduiRocket}/>
            <source media="(max-width: 639px)" srcSet={mobileRocketDdui}/>
            <img src={dduiRocket} alt="ddui_rocket" className={'w-[32.778vw] sm:w-[12.292vw]'}/>
          </picture>
        </CSSTransition>
        <CSSTransition in={showHomeContent4} timeout={1100} classNames={'home-content-4-4'} mountOnEnter unmountOnExit>
          <div className={'absolute top-[111.111vw] sm:top-[16.198vw] left-[50vw]'}>
            <img src={home11} alt={'home_11'} className={'w-[40vw] sm:w-[20.104vw] relative'}/>
            <div
              className={'absolute top-0 w-[100%] h-[100%] flex justify-center items-center font-[nanum-square-round-eb] text-[5.556vw] sm:text-[2.604vw] leading-none pb-[3.889vw] sm:pb-[1.458vw]'}>
              <span className={'text-[#ffffff]'}>학습자</span><span className={'text-[#FFFA7E]'}>&nbsp;99.3%</span>
            </div>
          </div>
        </CSSTransition>
        <div className={'hidden sm:block absolute top-[48.333vw] left-[50%] -translate-x-1/2 font-[pretendard-m] text-[1.25vw] text-[#9AB0FF] leading-none'}>
          [2023년1~4월 학습자 6009명 학습데이터 기준/ 1회차 대비 2~3회차 학습결과 분석]
        </div>
        <div className={'block sm:hidden w-full absolute top-[197.778vw] font-[pretendard-m] text-[3.333vw] text-[#9AB0FF] text-center leading-snug'}>
          [2023년1~4월 학습자 6009명 학습데이터 기준/<br/>
          1회차 대비 2~3회차 학습결과 분석]
        </div>
      </div>
      <div className={'w-full h-[355vw] sm:h-[69.844vw] relative'} ref={homeContent5}>
        <CSSTransition in={showHomeContent5} timeout={700} classNames={'home-content-5-1'} mountOnEnter unmountOnExit>
          <div className={'w-full absolute top-[35.556vw] sm:top-[12.5vw]'}>
            <div className={'hidden sm:block font-[nanum-square-round-eb] text-[2.604vw] text-center leading-none'}>
              <span className={'text-[#2046E4]'}>일프로연산</span>의 생생한 리얼 후기
            </div>
            <div className={'block sm:hidden font-[nanum-square-round-eb] text-[7.778vw] text-center leading-snug'}>
              <span className={'text-[#2046E4]'}>일프로연산</span>의<br/>
              생생한 리얼 후기
            </div>
            <div className={'hidden sm:block font-[pretendard-m] text-[1.823vw] mt-[1.667vw] text-center leading-none'}>
              일프로연산 이용자들의 이야기를 들어보세요!
            </div>
            <div className={'block sm:hidden font-[pretendard-m] text-[5vw] mt-[8.333vw] text-center leading-snug'}>
              일프로연산 이용자들의 이야기를<br/>
              들어보세요!
            </div>
          </div>
        </CSSTransition>
        <CSSTransition in={showHomeContent5} timeout={900} classNames={'home-content-5-2'} mountOnEnter unmountOnExit>
          <div className={'w-full absolute top-[91.389vw] sm:top-[20.26vw]'}>
            <Swiper
              onSwiper={(swiper) => (swiperRef.current = swiper)}
              onSliderMove={handleDragStart}
              onTouchEnd={handleDragEnd}
              onMouseUp={handleDragEnd}
              modules={[Pagination, Autoplay, Navigation]}
              slidesPerView={1}
              loop={true}
              pagination={{
                clickable: true,
                bulletClass: 'swiper-pagination-bullet custom-bullet', // 커스텀 클래스 추가
                bulletActiveClass: 'swiper-pagination-bullet-active custom-bullet-active', // 커스텀 클래스 추가
                el: '.custom-swiper-pagination-1', // 커스텀 페이지네이션
              }}
              // autoplay={{ delay: 4000, disableOnInteraction: false, pauseOnMouseEnter: true }}
            >
              <SwiperSlide style={{ display: 'flex', justifyContent: 'center' }}>
                <div className={'w-full sm:w-[62.5vw] h-[227.778vw] sm:h-[37.083vw] relative rounded-none sm:rounded-[2.083vw] overflow-hidden bg-[#eaf1ff]'}>
                  <div className={'absolute top-[119.722vw] sm:top-[3.333vw] left-[4.722vw] sm:left-[4.063vw]'}>
                    <img src={review1} alt={'review_1'} className={'w-[90.833vw] sm:w-[27.813vw]'}/>
                  </div>
                  <div
                    className={'w-full sm:w-auto absolute top-[22.222vw] sm:top-[5.26vw] left-auto sm:left-[37.448vw] flex sm:flex-none justify-center font-[nanum-square-round-eb] text-[7.222vw] sm:text-[1.823vw] leading-none'}>엄마
                    나 100점 맞았어!
                  </div>
                  <div className={'w-[70vw] sm:w-[20.99vw] absolute top-[36.111vw] sm:top-[9.167vw] left-[15vw] sm:left-[37.448vw] font-[pretendard-m] text-[4.444vw] sm:text-[1.198vw]'}>
                    매일 30분씩 정해놓고 하던 연산공부.<br/>
                    암산 실력도 진짜 많이 늘었고,<br/>
                    단원평가 저번 달에는 2개 틀리더니 이번에는 100점 맞아옴 ㅎㅎㅎ<br/>
                    이로써 일프로연산의 힘을 또 한번 증명!<br/>
                    상위 1% 달성하는 재미에 푹 빠져서 사진 찍어 달라고 포즈도 취하시고 세상에서 수학이 제일 어렵다 했었는데 수학과 점점 더 친해지고 있는 연우~
                  </div>
                  <div className={'absolute top-[105.833vw] sm:top-[26.667vw] left-[15vw] sm:left-[37.448vw] font-[pretendard-m] text-[3.889vw] sm:text-[1.042vw] leading-none'}>
                    yul_m***님
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide style={{ display: 'flex', justifyContent: 'center' }}>
                <div className={'w-full sm:w-[62.5vw] h-[227.778vw] sm:h-[37.083vw] relative rounded-none sm:rounded-[2.083vw] overflow-hidden bg-[#eaf1ff]'}>
                  <div className={'absolute top-[119.722vw] sm:top-[3.333vw] left-[4.722vw] sm:left-[4.063vw]'}>
                    <img src={review2} alt={'review_2'} className={'w-[90.833vw] sm:w-[27.813vw]'}/>
                  </div>
                  <div
                    className={'w-full sm:w-auto absolute top-[22.222vw] sm:top-[5.26vw] left-auto sm:left-[37.448vw] flex sm:flex-none justify-center font-[nanum-square-round-eb] text-[7.222vw] sm:text-[1.823vw] leading-none'}>일프로연산으로
                    자신감UP
                  </div>
                  <div className={'w-[70vw] sm:w-[20.99vw] absolute top-[36.111vw] sm:top-[9.167vw] left-[15vw] sm:left-[37.448vw] font-[pretendard-m] text-[4.444vw] sm:text-[1.198vw]'}>
                    친구들이랑 구구단 외우기를 했는데, 자기 생각에는 친구들보다 잘한 것 같다면서 신이 나서 얘기를 하더라고요~<br/>
                    처음에 구구단 공부 시작할 때는 짜증 가득한 얼굴로 한 개(15문제)를 푸는데도 시간이 너무 많이 걸렸거든요~ 요즘에는 시작한 지 얼마 안 되었는데도 5개(100문제 가량) 다 풀었다고 보여주는데 100점을 맞더라고요!! 실수하는 부분도 많이 줄었답니다!
                  </div>
                  <div className={'absolute top-[105.833vw] sm:top-[26.667vw] left-[15vw] sm:left-[37.448vw] font-[pretendard-m] text-[3.889vw] sm:text-[1.042vw] leading-none'}>
                    silver.l***님
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide style={{ display: 'flex', justifyContent: 'center' }}>
                <div className={'w-full sm:w-[62.5vw] h-[227.778vw] sm:h-[37.083vw] relative rounded-none sm:rounded-[2.083vw] overflow-hidden bg-[#eaf1ff]'}>
                  <div className={'absolute top-[119.722vw] sm:top-[3.333vw] left-[4.722vw] sm:left-[4.063vw]'}>
                    <img src={review3} alt={'review_3'} className={'w-[90.833vw] sm:w-[27.813vw]'}/>
                  </div>
                  <div
                    className={'w-full sm:w-auto absolute top-[22.222vw] sm:top-[5.26vw] left-auto sm:left-[37.448vw] flex sm:flex-none justify-center font-[nanum-square-round-eb] text-[7.222vw] sm:text-[1.823vw] leading-none'}>선생님도
                    인정한 연산 실력
                  </div>
                  <div className={'w-[70vw] sm:w-[20.99vw] absolute top-[36.111vw] sm:top-[9.167vw] left-[15vw] sm:left-[37.448vw] font-[pretendard-m] text-[4.444vw] sm:text-[1.198vw]'}>
                    수학을 엄청~~~ 싫어한 아이!<br/>
                    특히 연산을 재미없다고, 하기 싫다고 할 정도였어요.<br/>
                    그러던 어느날 점차 관심을 보이면서 시작하는게 눈에 띄게 되었고, 요즘 학교에서 덧셈과 뺄셈 받아올림과 받아내림을 하고 있는데 잘해서 담임 선생님께 칭찬을 받았다고 합니다. 아이가 100점이라고 좋아해요~ 일프로연산의 효과가 뒤늦게 나타나네요♡
                  </div>
                  <div className={'absolute top-[105.833vw] sm:top-[26.667vw] left-[15vw] sm:left-[37.448vw] font-[pretendard-m] text-[3.889vw] sm:text-[1.042vw] leading-none'}>
                    cyh0***님
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide style={{ display: 'flex', justifyContent: 'center' }}>
                <div className={'w-full sm:w-[62.5vw] h-[227.778vw] sm:h-[37.083vw] relative rounded-none sm:rounded-[2.083vw] overflow-hidden bg-[#eaf1ff]'}>
                  <div className={'absolute top-[119.722vw] sm:top-[3.333vw] left-[4.722vw] sm:left-[4.063vw]'}>
                    <img src={review4} alt={'review_4'} className={'w-[90.833vw] sm:w-[27.813vw]'}/>
                  </div>
                  <div
                    className={'w-full sm:w-auto absolute top-[22.222vw] sm:top-[5.26vw] left-auto sm:left-[37.448vw] flex sm:flex-none justify-center font-[nanum-square-round-eb] text-[7.222vw] sm:text-[1.823vw] leading-none'}>스스로하며
                    실력이 쑥쑥!
                  </div>
                  <div className={'w-[70vw] sm:w-[20.99vw] absolute top-[36.111vw] sm:top-[9.167vw] left-[15vw] sm:left-[37.448vw] font-[pretendard-m] text-[4.444vw] sm:text-[1.198vw]'}>
                    일프로연산을 시키는데 아이가 스스로, 수시로 하려고 해요!<br/>
                    사용한 지 6개월 정도 지났는데 덕분에 종이 문제집을 지겨워하지 않게 되었어요!<br/>
                    어렵지 않고 쓱쓱 풀리니 본인도 약간 놀라워하며 빠른 시간에 일주일 치 숙제를 뚝딱 해냅니다!!<br/>
                    이제는 세 자릿수 덧셈 한쪽 푸는데 1분 남짓 걸리네요~^^
                  </div>
                  <div className={'absolute top-[105.833vw] sm:top-[26.667vw] left-[15vw] sm:left-[37.448vw] font-[pretendard-m] text-[3.889vw] sm:text-[1.042vw] leading-none'}>
                    lovely.ji***님
                  </div>
                </div>
              </SwiperSlide>
              <div className="custom-swiper-pagination-1"></div>
              {/* 커스텀 네비게이션 버튼 */}
              <button
                onClick={() => swiperRef.current.slidePrev()}
                className={`absolute top-[111.667vw] sm:top-[14.531vw] left-[3.333vw] sm:left-[13.854vw] z-[1] w-[18vw] sm:w-[6.771vw] h-[18vw] sm:h-[6.771vw] rounded-full flex justify-center items-center shadow-lg bg-[#ffffff] cursor-pointer ${isDragging ? 'fade-out' : 'fade-in'}`}
              >
                <Prev className={'w-[5.556vw] sm:w-[2.083vw] h-auto'}/>
              </button>
              <button
                onClick={() => swiperRef.current.slideNext()}
                className={`absolute top-[111.667vw] sm:top-[14.531vw] right-[3.333vw] sm:right-[13.854vw] z-[1] w-[18vw] sm:w-[6.771vw] h-[18vw] sm:h-[6.771vw] rounded-full flex justify-center items-center shadow-lg bg-[#ffffff] cursor-pointer ${isDragging ? 'fade-out' : 'fade-in'}`}
              >
                <Next className={'w-[5.556vw] sm:w-[2.083vw] h-auto'}/>
              </button>
            </Swiper>
          </div>
        </CSSTransition>
      </div>
      <div className={'relative'} ref={homeContent6}>
        <picture className={'relative'}>
          <source media="(min-width: 640px)" srcSet={homeBg5}/>
          <source media="(max-width: 639px)" srcSet={mobileReviewBg}/>
          <img src={homeBg5} alt="home_bg_5" className={'w-full'}/>
        </picture>
        <CSSTransition in={showHomeContent6} timeout={700} classNames={'home-content-6-1'} mountOnEnter unmountOnExit>
          <div className={'w-full h-[270px] absolute top-[18.611vw] sm:top-[7.917vw] flex justify-center'}>
            <div className={'hidden sm:block font-[nanum-square-round-eb] text-[2.604vw] text-[#ffffff] leading-none'}>100만{`\u00A0`}<span className={'text-[#FFFB9F]'}>1등</span>{`\u00A0`}맘들의 이용 후기
            </div>
            <div className={'block sm:hidden font-[nanum-square-round-eb] text-[8.333vw] text-[#ffffff] text-center'}>
              100만{`\u00A0`}<span className={'text-[#FFFB9F]'}>1등</span> 맘들의<br/>
              이용 후기
            </div>
          </div>
        </CSSTransition>
        <div className={'w-full absolute top-[53.333vw] sm:top-[14.063vw]'}>
          <div>
            <Swiper
              ref={infiniteRollingBanner1Ref}
              modules={[Autoplay]}
              loop={true}
              spaceBetween={40}
              slidesPerView={1} // 기본값을 1로 설정
              speed={5100} // 슬라이드 전환 속도
              autoplay={{
                delay: 0, // 자동 전환 간 지연 시간
                disableOnInteraction: false,
                pauseOnMouseEnter: true, // 마우스 호버 시 일시 정지
              }}
              breakpoints={{
                // 화면의 너비가 640px 이상일 때
                640: {
                  spaceBetween: 110,
                  slidesPerView: 5, // 640px 이상일 때 슬라이드 5개 보이기
                }
              }}
            >
              <SwiperSlide>
                <div className={'w-[100vw] sm:w-[20vw] h-[61.111vw] sm:h-[12.083vw] relative bg-[#ffffff] shadow rounded-[5.556vw] sm:rounded-[1.042vw] font-[pretendard-m]'}>
                  <div className={'absolute top-[10vw] sm:top-[2.292vw] left-[8.889vw] sm:left-[1.563vw] flex'}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFD249" className="w-[5.556vw] sm:w-[0.885vw]">
                      <path fillRule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                            clipRule="evenodd"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFD249" className="w-[5.556vw] sm:w-[0.885vw]">
                      <path fillRule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                            clipRule="evenodd"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFD249" className="w-[5.556vw] sm:w-[0.885vw]">
                      <path fillRule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                            clipRule="evenodd"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFD249" className="w-[5.556vw] sm:w-[0.885vw]">
                      <path fillRule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                            clipRule="evenodd"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFD249" className="w-[5.556vw] sm:w-[0.885vw]">
                      <path fillRule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                            clipRule="evenodd"/>
                    </svg>
                  </div>
                  <div className={'absolute top-[18.889vw] sm:top-[3.958vw] left-[8.889vw] sm:left-[1.563vw] font-[pretendard-sb] text-[5.556vw] sm:text-[1.042vw] leading-none'}>
                    실시간으로 아이의 진행 상황을 확인해요.
                  </div>
                  <div className={'w-[82.222vw] sm:w-[16.875vw] absolute top-[30vw] sm:top-[6.042vw] left-[8.889vw] sm:left-[1.563vw] text-[4.167vw] sm:text-[0.885vw] leading-snug'}>
                    학부모앱을 통해 아이가 잘 안되는 부분을 알림으로 받고 다시 풀어보게 하여 유익합니다.
                  </div>
                  <div className={'absolute top-[46.111vw] sm:top-[9.063vw] left-[8.889vw] sm:left-[1.563vw] text-[4.444vw] sm:text-[0.833vw] leading-none'}>
                    ma joie*****님
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={'w-[100vw] sm:w-[20vw] h-[61.111vw] sm:h-[12.083vw] relative bg-[#ffffff] shadow rounded-[5.556vw] sm:rounded-[1.042vw] font-[pretendard-m]'}>
                  <div className={'absolute top-[10vw] sm:top-[2.292vw] left-[8.889vw] sm:left-[1.563vw] flex'}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFD249" className="w-[5.556vw] sm:w-[0.885vw]">
                      <path fillRule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                            clipRule="evenodd"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFD249" className="w-[5.556vw] sm:w-[0.885vw]">
                      <path fillRule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                            clipRule="evenodd"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFD249" className="w-[5.556vw] sm:w-[0.885vw]">
                      <path fillRule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                            clipRule="evenodd"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFD249" className="w-[5.556vw] sm:w-[0.885vw]">
                      <path fillRule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                            clipRule="evenodd"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFD249" className="w-[5.556vw] sm:w-[0.885vw]">
                      <path fillRule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                            clipRule="evenodd"/>
                    </svg>
                  </div>
                  <div className={'absolute top-[18.889vw] sm:top-[3.958vw] left-[8.889vw] sm:left-[1.563vw] font-[pretendard-sb] text-[5.556vw] sm:text-[1.042vw] leading-none'}>
                    아이가 스스로 찾아서 학습해요.
                  </div>
                  <div className={'w-[82.222vw] sm:w-[16.875vw] absolute top-[30vw] sm:top-[6.042vw] left-[8.889vw] sm:left-[1.563vw] text-[4.167vw] sm:text-[0.885vw] leading-snug'}>
                    수학을 많이 어려워하고 싫어했는데 이제는 친구와 경쟁하면서 스스로 찾아서 학습해요.
                  </div>
                  <div className={'absolute top-[46.111vw] sm:top-[9.063vw] left-[8.889vw] sm:left-[1.563vw] text-[4.444vw] sm:text-[0.833vw] leading-none'}>
                    kimb*****님
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={'w-[100vw] sm:w-[20vw] h-[61.111vw] sm:h-[12.083vw] relative bg-[#ffffff] shadow rounded-[5.556vw] sm:rounded-[1.042vw] font-[pretendard-m]'}>
                  <div className={'absolute top-[10vw] sm:top-[2.292vw] left-[8.889vw] sm:left-[1.563vw] flex'}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFD249" className="w-[5.556vw] sm:w-[0.885vw]">
                      <path fillRule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                            clipRule="evenodd"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFD249" className="w-[5.556vw] sm:w-[0.885vw]">
                      <path fillRule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                            clipRule="evenodd"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFD249" className="w-[5.556vw] sm:w-[0.885vw]">
                      <path fillRule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                            clipRule="evenodd"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFD249" className="w-[5.556vw] sm:w-[0.885vw]">
                      <path fillRule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                            clipRule="evenodd"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFD249" className="w-[5.556vw] sm:w-[0.885vw]">
                      <path fillRule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                            clipRule="evenodd"/>
                    </svg>
                  </div>
                  <div className={'absolute top-[18.889vw] sm:top-[3.958vw] left-[8.889vw] sm:left-[1.563vw] font-[pretendard-sb] text-[5.556vw] sm:text-[1.042vw] leading-none'}>
                    큰 아이, 작은 아이 둘 다 좋아해요^^
                  </div>
                  <div className={'w-[82.222vw] sm:w-[16.875vw] absolute top-[30vw] sm:top-[6.042vw] left-[8.889vw] sm:left-[1.563vw] text-[4.167vw] sm:text-[0.885vw] leading-snug'}>
                    큰 아이 해주려고 구매한 건데 한 개 구매해서 5개까지 계정 만들 수 있다는게 정말 좋네요.
                  </div>
                  <div className={'absolute top-[46.111vw] sm:top-[9.063vw] left-[8.889vw] sm:left-[1.563vw] text-[4.444vw] sm:text-[0.833vw] leading-none'}>
                    love******님
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={'w-[100vw] sm:w-[20vw] h-[61.111vw] sm:h-[12.083vw] relative bg-[#ffffff] shadow rounded-[5.556vw] sm:rounded-[1.042vw] font-[pretendard-m]'}>
                  <div className={'absolute top-[10vw] sm:top-[2.292vw] left-[8.889vw] sm:left-[1.563vw] flex'}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFD249" className="w-[5.556vw] sm:w-[0.885vw]">
                      <path fillRule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                            clipRule="evenodd"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFD249" className="w-[5.556vw] sm:w-[0.885vw]">
                      <path fillRule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                            clipRule="evenodd"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFD249" className="w-[5.556vw] sm:w-[0.885vw]">
                      <path fillRule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                            clipRule="evenodd"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFD249" className="w-[5.556vw] sm:w-[0.885vw]">
                      <path fillRule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                            clipRule="evenodd"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFD249" className="w-[5.556vw] sm:w-[0.885vw]">
                      <path fillRule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                            clipRule="evenodd"/>
                    </svg>
                  </div>
                  <div className={'absolute top-[18.889vw] sm:top-[3.958vw] left-[8.889vw] sm:left-[1.563vw] font-[pretendard-sb] text-[5.556vw] sm:text-[1.042vw] leading-none'}>
                    연산속도가 빨라졌어요.
                  </div>
                  <div className={'w-[82.222vw] sm:w-[16.875vw] absolute top-[30vw] sm:top-[6.042vw] left-[8.889vw] sm:left-[1.563vw] text-[4.167vw] sm:text-[0.885vw] leading-snug'}>
                    상위 10%, 상위 1%가 되고 싶어서 열심히 하네요. 놀이처럼 아바타 꾸미기도 있어서 좋아해요.
                  </div>
                  <div className={'absolute top-[46.111vw] sm:top-[9.063vw] left-[8.889vw] sm:left-[1.563vw] text-[4.444vw] sm:text-[0.833vw] leading-none'}>
                    lowd***님
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={'w-[100vw] sm:w-[20vw] h-[61.111vw] sm:h-[12.083vw] relative bg-[#ffffff] shadow rounded-[5.556vw] sm:rounded-[1.042vw] font-[pretendard-m]'}>
                  <div className={'absolute top-[10vw] sm:top-[2.292vw] left-[8.889vw] sm:left-[1.563vw] flex'}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFD249" className="w-[5.556vw] sm:w-[0.885vw]">
                      <path fillRule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                            clipRule="evenodd"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFD249" className="w-[5.556vw] sm:w-[0.885vw]">
                      <path fillRule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                            clipRule="evenodd"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFD249" className="w-[5.556vw] sm:w-[0.885vw]">
                      <path fillRule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                            clipRule="evenodd"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFD249" className="w-[5.556vw] sm:w-[0.885vw]">
                      <path fillRule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                            clipRule="evenodd"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFD249" className="w-[5.556vw] sm:w-[0.885vw]">
                      <path fillRule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                            clipRule="evenodd"/>
                    </svg>
                  </div>
                  <div className={'absolute top-[18.889vw] sm:top-[3.958vw] left-[8.889vw] sm:left-[1.563vw] font-[pretendard-sb] text-[5.556vw] sm:text-[1.042vw] leading-none'}>
                    그림으로 개념 설명이 잘 되어있어요.
                  </div>
                  <div className={'w-[82.222vw] sm:w-[16.875vw] absolute top-[30vw] sm:top-[6.042vw] left-[8.889vw] sm:left-[1.563vw] text-[4.167vw] sm:text-[0.885vw] leading-snug'}>
                    개념 이해를 돕기 위헤 그림으로 자세히 설명해 주는게 진짜 좋네요~ 아이가 잘 이해해요!
                  </div>
                  <div className={'absolute top-[46.111vw] sm:top-[9.063vw] left-[8.889vw] sm:left-[1.563vw] text-[4.444vw] sm:text-[0.833vw] leading-none'}>
                    gomu******님
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={'w-[100vw] sm:w-[20vw] h-[61.111vw] sm:h-[12.083vw] relative bg-[#ffffff] shadow rounded-[5.556vw] sm:rounded-[1.042vw] font-[pretendard-m]'}>
                  <div className={'absolute top-[10vw] sm:top-[2.292vw] left-[8.889vw] sm:left-[1.563vw] flex'}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFD249" className="w-[5.556vw] sm:w-[0.885vw]">
                      <path fillRule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                            clipRule="evenodd"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFD249" className="w-[5.556vw] sm:w-[0.885vw]">
                      <path fillRule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                            clipRule="evenodd"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFD249" className="w-[5.556vw] sm:w-[0.885vw]">
                      <path fillRule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                            clipRule="evenodd"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFD249" className="w-[5.556vw] sm:w-[0.885vw]">
                      <path fillRule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                            clipRule="evenodd"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFD249" className="w-[5.556vw] sm:w-[0.885vw]">
                      <path fillRule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                            clipRule="evenodd"/>
                    </svg>
                  </div>
                  <div className={'absolute top-[18.889vw] sm:top-[3.958vw] left-[8.889vw] sm:left-[1.563vw] font-[pretendard-sb] text-[5.556vw] sm:text-[1.042vw] leading-none'}>
                    너무 좋아서 재구매 했어요~
                  </div>
                  <div className={'w-[82.222vw] sm:w-[16.875vw] absolute top-[30vw] sm:top-[6.042vw] left-[8.889vw] sm:left-[1.563vw] text-[4.167vw] sm:text-[0.885vw] leading-snug'}>
                    기존에 이용권 구매라고 너무 좋아서 하나 더 구매했어요^^ 아이 넷 모두 하나로 쓸 수 있어 좋아요.
                  </div>
                  <div className={'absolute top-[46.111vw] sm:top-[9.063vw] left-[8.889vw] sm:left-[1.563vw] text-[4.444vw] sm:text-[0.833vw] leading-none'}>
                    dori****님
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
          <div className={'mt-[6.667vw] sm:mt-[1.25vw]'}>
            <Swiper
              ref={infiniteRollingBanner2Ref}
              modules={[Autoplay]}
              loop={true}
              spaceBetween={40} // 기본값을 40px로 설정
              slidesPerView={1} // 기본값을 1로 설정
              speed={4900} // 슬라이드 전환 속도
              autoplay={{
                delay: 0, // 자동 전환 간 지연 시간
                disableOnInteraction: false,
                reverseDirection: true,
                pauseOnMouseEnter: true, // 마우스 호버 시 일시 정지
              }}
              breakpoints={{
                // 화면의 너비가 640px 이상일 때
                640: {
                  spaceBetween: 110,
                  slidesPerView: 5, // 640px 이상일 때 슬라이드 5개 보이기
                }
              }}
            >
              <SwiperSlide>
                <div className={'w-[100vw] sm:w-[20vw] h-[61.111vw] sm:h-[12.083vw] relative bg-[#ffffff] shadow rounded-[5.556vw] sm:rounded-[1.042vw] font-[pretendard-m]'}>
                  <div className={'absolute top-[10vw] sm:top-[2.292vw] left-[8.889vw] sm:left-[1.563vw] flex'}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFD249" className="w-[5.556vw] sm:w-[0.885vw]">
                      <path fillRule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                            clipRule="evenodd"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFD249" className="w-[5.556vw] sm:w-[0.885vw]">
                      <path fillRule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                            clipRule="evenodd"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFD249" className="w-[5.556vw] sm:w-[0.885vw]">
                      <path fillRule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                            clipRule="evenodd"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFD249" className="w-[5.556vw] sm:w-[0.885vw]">
                      <path fillRule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                            clipRule="evenodd"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFD249" className="w-[5.556vw] sm:w-[0.885vw]">
                      <path fillRule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                            clipRule="evenodd"/>
                    </svg>
                  </div>
                  <div className={'absolute top-[18.889vw] sm:top-[3.958vw] left-[8.889vw] sm:left-[1.563vw] font-[pretendard-sb] text-[5.556vw] sm:text-[1.042vw] leading-none'}>
                    응원메시지로 학습을 독려해요.
                  </div>
                  <div className={'w-[82.222vw] sm:w-[16.875vw] absolute top-[30vw] sm:top-[6.042vw] left-[8.889vw] sm:left-[1.563vw] text-[4.167vw] sm:text-[0.885vw] leading-snug'}>
                    학부모앱으로 짧은 응원 메시지를 보내주면 아이가 매우 좋아해요~아이도 부모도 대만족입니다.
                  </div>
                  <div className={'absolute top-[46.111vw] sm:top-[9.063vw] left-[8.889vw] sm:left-[1.563vw] text-[4.444vw] sm:text-[0.833vw] leading-none'}>
                    정***님
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={'w-[100vw] sm:w-[20vw] h-[61.111vw] sm:h-[12.083vw] relative bg-[#ffffff] shadow rounded-[5.556vw] sm:rounded-[1.042vw] font-[pretendard-m]'}>
                  <div className={'absolute top-[10vw] sm:top-[2.292vw] left-[8.889vw] sm:left-[1.563vw] flex'}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFD249" className="w-[5.556vw] sm:w-[0.885vw]">
                      <path fillRule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                            clipRule="evenodd"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFD249" className="w-[5.556vw] sm:w-[0.885vw]">
                      <path fillRule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                            clipRule="evenodd"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFD249" className="w-[5.556vw] sm:w-[0.885vw]">
                      <path fillRule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                            clipRule="evenodd"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFD249" className="w-[5.556vw] sm:w-[0.885vw]">
                      <path fillRule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                            clipRule="evenodd"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFD249" className="w-[5.556vw] sm:w-[0.885vw]">
                      <path fillRule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                            clipRule="evenodd"/>
                    </svg>
                  </div>
                  <div className={'absolute top-[18.889vw] sm:top-[3.958vw] left-[8.889vw] sm:left-[1.563vw] font-[pretendard-sb] text-[5.556vw] sm:text-[1.042vw] leading-none'}>
                    학습 보고서가 오는 게 좋아요.
                  </div>
                  <div className={'w-[82.222vw] sm:w-[16.875vw] absolute top-[30vw] sm:top-[6.042vw] left-[8.889vw] sm:left-[1.563vw] text-[4.167vw] sm:text-[0.885vw] leading-snug'}>
                    취약점과 우수 스테이지를 일별로 디테일하게 알려줘서 좋아요. 엄마표 수학에 날개 달았어요!
                  </div>
                  <div className={'absolute top-[46.111vw] sm:top-[9.063vw] left-[8.889vw] sm:left-[1.563vw] text-[4.444vw] sm:text-[0.833vw] leading-none'}>
                    ekyo*****님
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={'w-[100vw] sm:w-[20vw] h-[61.111vw] sm:h-[12.083vw] relative bg-[#ffffff] shadow rounded-[5.556vw] sm:rounded-[1.042vw] font-[pretendard-m]'}>
                  <div className={'absolute top-[10vw] sm:top-[2.292vw] left-[8.889vw] sm:left-[1.563vw] flex'}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFD249" className="w-[5.556vw] sm:w-[0.885vw]">
                      <path fillRule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                            clipRule="evenodd"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFD249" className="w-[5.556vw] sm:w-[0.885vw]">
                      <path fillRule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                            clipRule="evenodd"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFD249" className="w-[5.556vw] sm:w-[0.885vw]">
                      <path fillRule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                            clipRule="evenodd"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFD249" className="w-[5.556vw] sm:w-[0.885vw]">
                      <path fillRule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                            clipRule="evenodd"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFD249" className="w-[5.556vw] sm:w-[0.885vw]">
                      <path fillRule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                            clipRule="evenodd"/>
                    </svg>
                  </div>
                  <div className={'absolute top-[18.889vw] sm:top-[3.958vw] left-[8.889vw] sm:left-[1.563vw] font-[pretendard-sb] text-[5.556vw] sm:text-[1.042vw] leading-none'}>
                    아이들이 흥미를 느끼고 즐거워해요.
                  </div>
                  <div className={'w-[82.222vw] sm:w-[16.875vw] absolute top-[30vw] sm:top-[6.042vw] left-[8.889vw] sm:left-[1.563vw] text-[4.167vw] sm:text-[0.885vw] leading-snug'}>
                    문제를 풀수록 생기는 다이아몬드를 모으기 위해 자유학습까지 챙겨 학습해요^^
                  </div>
                  <div className={'absolute top-[46.111vw] sm:top-[9.063vw] left-[8.889vw] sm:left-[1.563vw] text-[4.444vw] sm:text-[0.833vw] leading-none'}>
                    dews******님
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={'w-[100vw] sm:w-[20vw] h-[61.111vw] sm:h-[12.083vw] relative bg-[#ffffff] shadow rounded-[5.556vw] sm:rounded-[1.042vw] font-[pretendard-m]'}>
                  <div className={'absolute top-[10vw] sm:top-[2.292vw] left-[8.889vw] sm:left-[1.563vw] flex'}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFD249" className="w-[5.556vw] sm:w-[0.885vw]">
                      <path fillRule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                            clipRule="evenodd"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFD249" className="w-[5.556vw] sm:w-[0.885vw]">
                      <path fillRule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                            clipRule="evenodd"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFD249" className="w-[5.556vw] sm:w-[0.885vw]">
                      <path fillRule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                            clipRule="evenodd"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFD249" className="w-[5.556vw] sm:w-[0.885vw]">
                      <path fillRule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                            clipRule="evenodd"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFD249" className="w-[5.556vw] sm:w-[0.885vw]">
                      <path fillRule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                            clipRule="evenodd"/>
                    </svg>
                  </div>
                  <div className={'absolute top-[18.889vw] sm:top-[3.958vw] left-[8.889vw] sm:left-[1.563vw] font-[pretendard-sb] text-[5.556vw] sm:text-[1.042vw] leading-none'}>
                    지면은 숙제같이 느끼는데 이건 좋아해요.
                  </div>
                  <div className={'w-[82.222vw] sm:w-[16.875vw] absolute top-[30vw] sm:top-[6.042vw] left-[8.889vw] sm:left-[1.563vw] text-[4.167vw] sm:text-[0.885vw] leading-snug'}>
                    아드님은 상위 1프로에 도전하느라 빨리 풀고 따님은 늦어도 차분히 풀어서 100점 맞아요.
                  </div>
                  <div className={'absolute top-[46.111vw] sm:top-[9.063vw] left-[8.889vw] sm:left-[1.563vw] text-[4.444vw] sm:text-[0.833vw] leading-none'}>
                    ymn1***님
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={'w-[100vw] sm:w-[20vw] h-[61.111vw] sm:h-[12.083vw] relative bg-[#ffffff] shadow rounded-[5.556vw] sm:rounded-[1.042vw] font-[pretendard-m]'}>
                  <div className={'absolute top-[10vw] sm:top-[2.292vw] left-[8.889vw] sm:left-[1.563vw] flex'}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFD249" className="w-[5.556vw] sm:w-[0.885vw]">
                      <path fillRule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                            clipRule="evenodd"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFD249" className="w-[5.556vw] sm:w-[0.885vw]">
                      <path fillRule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                            clipRule="evenodd"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFD249" className="w-[5.556vw] sm:w-[0.885vw]">
                      <path fillRule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                            clipRule="evenodd"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFD249" className="w-[5.556vw] sm:w-[0.885vw]">
                      <path fillRule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                            clipRule="evenodd"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFD249" className="w-[5.556vw] sm:w-[0.885vw]">
                      <path fillRule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                            clipRule="evenodd"/>
                    </svg>
                  </div>
                  <div className={'absolute top-[18.889vw] sm:top-[3.958vw] left-[8.889vw] sm:left-[1.563vw] font-[pretendard-sb] text-[5.556vw] sm:text-[1.042vw] leading-none'}>
                    구성이 알차고 튼튼해요.
                  </div>
                  <div className={'w-[82.222vw] sm:w-[16.875vw] absolute top-[30vw] sm:top-[6.042vw] left-[8.889vw] sm:left-[1.563vw] text-[4.167vw] sm:text-[0.885vw] leading-snug'}>
                    가격에 비해 부실한 앱을 쓰다가 넘어왔어요. 아이들 눈높이에 맞춰서 너무 잘 만들었어요~
                  </div>
                  <div className={'absolute top-[46.111vw] sm:top-[9.063vw] left-[8.889vw] sm:left-[1.563vw] text-[4.444vw] sm:text-[0.833vw] leading-none'}>
                    love***님
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={'w-[100vw] sm:w-[20vw] h-[61.111vw] sm:h-[12.083vw] relative bg-[#ffffff] shadow rounded-[5.556vw] sm:rounded-[1.042vw] font-[pretendard-m]'}>
                  <div className={'absolute top-[10vw] sm:top-[2.292vw] left-[8.889vw] sm:left-[1.563vw] flex'}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFD249" className="w-[5.556vw] sm:w-[0.885vw]">
                      <path fillRule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                            clipRule="evenodd"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFD249" className="w-[5.556vw] sm:w-[0.885vw]">
                      <path fillRule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                            clipRule="evenodd"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFD249" className="w-[5.556vw] sm:w-[0.885vw]">
                      <path fillRule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                            clipRule="evenodd"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFD249" className="w-[5.556vw] sm:w-[0.885vw]">
                      <path fillRule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                            clipRule="evenodd"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFD249" className="w-[5.556vw] sm:w-[0.885vw]">
                      <path fillRule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                            clipRule="evenodd"/>
                    </svg>
                  </div>
                  <div className={'absolute top-[18.889vw] sm:top-[3.958vw] left-[8.889vw] sm:left-[1.563vw] font-[pretendard-sb] text-[5.556vw] sm:text-[1.042vw] leading-none'}>
                    3일 체험하고 바로 결제했어요.
                  </div>
                  <div className={'w-[82.222vw] sm:w-[16.875vw] absolute top-[30vw] sm:top-[6.042vw] left-[8.889vw] sm:left-[1.563vw] text-[4.167vw] sm:text-[0.885vw] leading-snug'}>
                    사실 앱으로 학습하는 걸 시켜야 하나 고민이 많았는데, 그런 제 마음을 바꾸게 해준 앱이에요.
                  </div>
                  <div className={'absolute top-[46.111vw] sm:top-[9.063vw] left-[8.889vw] sm:left-[1.563vw] text-[4.444vw] sm:text-[0.833vw] leading-none'}>
                    am****님
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
          <div className={'mt-[6.667vw] sm:mt-[1.25vw]'}>
            <Swiper
              ref={infiniteRollingBanner3Ref}
              modules={[Autoplay]}
              loop={true}
              spaceBetween={40} // 기본값을 40px로 설정
              slidesPerView={1} // 기본값을 1로 설정
              speed={5000} // 슬라이드 전환 속도
              autoplay={{
                delay: 0, // 자동 전환 간 지연 시간
                disableOnInteraction: false,
                pauseOnMouseEnter: true, // 마우스 호버 시 일시 정지
              }}
              breakpoints={{
                // 화면의 너비가 640px 이상일 때
                640: {
                  spaceBetween: 110,
                  slidesPerView: 5, // 640px 이상일 때 슬라이드 5개 보이기
                }
              }}
            >
              <SwiperSlide>
                <div className={'w-[100vw] sm:w-[20vw] h-[61.111vw] sm:h-[12.083vw] relative bg-[#ffffff] shadow rounded-[5.556vw] sm:rounded-[1.042vw] font-[pretendard-m]'}>
                  <div className={'absolute top-[10vw] sm:top-[2.292vw] left-[8.889vw] sm:left-[1.563vw] flex'}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFD249" className="w-[5.556vw] sm:w-[0.885vw]">
                      <path fillRule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                            clipRule="evenodd"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFD249" className="w-[5.556vw] sm:w-[0.885vw]">
                      <path fillRule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                            clipRule="evenodd"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFD249" className="w-[5.556vw] sm:w-[0.885vw]">
                      <path fillRule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                            clipRule="evenodd"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFD249" className="w-[5.556vw] sm:w-[0.885vw]">
                      <path fillRule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                            clipRule="evenodd"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFD249" className="w-[5.556vw] sm:w-[0.885vw]">
                      <path fillRule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                            clipRule="evenodd"/>
                    </svg>
                  </div>
                  <div className={'absolute top-[18.889vw] sm:top-[3.958vw] left-[8.889vw] sm:left-[1.563vw] font-[pretendard-sb] text-[5.556vw] sm:text-[1.042vw] leading-none'}>
                    이동 중에 유튜브 대신 하게 해요.
                  </div>
                  <div className={'w-[82.222vw] sm:w-[16.875vw] absolute top-[30vw] sm:top-[6.042vw] left-[8.889vw] sm:left-[1.563vw] text-[4.167vw] sm:text-[0.885vw] leading-snug'}>
                    무거운 연산 문제집을 들고 다니지 않아도 어디서든 할 수 있어서 부담이 없습니다~
                  </div>
                  <div className={'absolute top-[46.111vw] sm:top-[9.063vw] left-[8.889vw] sm:left-[1.563vw] text-[4.444vw] sm:text-[0.833vw] leading-none'}>
                    suye*****님
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={'w-[100vw] sm:w-[20vw] h-[61.111vw] sm:h-[12.083vw] relative bg-[#ffffff] shadow rounded-[5.556vw] sm:rounded-[1.042vw] font-[pretendard-m]'}>
                  <div className={'absolute top-[10vw] sm:top-[2.292vw] left-[8.889vw] sm:left-[1.563vw] flex'}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFD249" className="w-[5.556vw] sm:w-[0.885vw]">
                      <path fillRule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                            clipRule="evenodd"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFD249" className="w-[5.556vw] sm:w-[0.885vw]">
                      <path fillRule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                            clipRule="evenodd"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFD249" className="w-[5.556vw] sm:w-[0.885vw]">
                      <path fillRule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                            clipRule="evenodd"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFD249" className="w-[5.556vw] sm:w-[0.885vw]">
                      <path fillRule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                            clipRule="evenodd"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFD249" className="w-[5.556vw] sm:w-[0.885vw]">
                      <path fillRule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                            clipRule="evenodd"/>
                    </svg>
                  </div>
                  <div className={'absolute top-[18.889vw] sm:top-[3.958vw] left-[8.889vw] sm:left-[1.563vw] font-[pretendard-sb] text-[5.556vw] sm:text-[1.042vw] leading-none'}>
                    선행학습도 어렵지 않아요.
                  </div>
                  <div className={'w-[82.222vw] sm:w-[16.875vw] absolute top-[30vw] sm:top-[6.042vw] left-[8.889vw] sm:left-[1.563vw] text-[4.167vw] sm:text-[0.885vw] leading-snug'}>
                    고학년 학습을 특별한 설명 없이도 그림을 보고 푸는 모습이 참 신기합니다.
                  </div>
                  <div className={'absolute top-[46.111vw] sm:top-[9.063vw] left-[8.889vw] sm:left-[1.563vw] text-[4.444vw] sm:text-[0.833vw] leading-none'}>
                    dear****님
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={'w-[100vw] sm:w-[20vw] h-[61.111vw] sm:h-[12.083vw] relative bg-[#ffffff] shadow rounded-[5.556vw] sm:rounded-[1.042vw] font-[pretendard-m]'}>
                  <div className={'absolute top-[10vw] sm:top-[2.292vw] left-[8.889vw] sm:left-[1.563vw] flex'}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFD249" className="w-[5.556vw] sm:w-[0.885vw]">
                      <path fillRule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                            clipRule="evenodd"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFD249" className="w-[5.556vw] sm:w-[0.885vw]">
                      <path fillRule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                            clipRule="evenodd"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFD249" className="w-[5.556vw] sm:w-[0.885vw]">
                      <path fillRule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                            clipRule="evenodd"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFD249" className="w-[5.556vw] sm:w-[0.885vw]">
                      <path fillRule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                            clipRule="evenodd"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFD249" className="w-[5.556vw] sm:w-[0.885vw]">
                      <path fillRule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                            clipRule="evenodd"/>
                    </svg>
                  </div>
                  <div className={'absolute top-[18.889vw] sm:top-[3.958vw] left-[8.889vw] sm:left-[1.563vw] font-[pretendard-sb] text-[5.556vw] sm:text-[1.042vw] leading-none'}>
                    책으로 연결하기 좋아요*^^*
                  </div>
                  <div className={'w-[82.222vw] sm:w-[16.875vw] absolute top-[30vw] sm:top-[6.042vw] left-[8.889vw] sm:left-[1.563vw] text-[4.167vw] sm:text-[0.885vw] leading-snug'}>
                    책으로 했다가 너무 싫어해서 앱으로 했더니 잘하네요. 그다음 책으로 연결해도 거부감 없어요.
                  </div>
                  <div className={'absolute top-[46.111vw] sm:top-[9.063vw] left-[8.889vw] sm:left-[1.563vw] text-[4.444vw] sm:text-[0.833vw] leading-none'}>
                    libr****님
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={'w-[100vw] sm:w-[20vw] h-[61.111vw] sm:h-[12.083vw] relative bg-[#ffffff] shadow rounded-[5.556vw] sm:rounded-[1.042vw] font-[pretendard-m]'}>
                  <div className={'absolute top-[10vw] sm:top-[2.292vw] left-[8.889vw] sm:left-[1.563vw] flex'}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFD249" className="w-[5.556vw] sm:w-[0.885vw]">
                      <path fillRule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                            clipRule="evenodd"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFD249" className="w-[5.556vw] sm:w-[0.885vw]">
                      <path fillRule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                            clipRule="evenodd"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFD249" className="w-[5.556vw] sm:w-[0.885vw]">
                      <path fillRule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                            clipRule="evenodd"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFD249" className="w-[5.556vw] sm:w-[0.885vw]">
                      <path fillRule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                            clipRule="evenodd"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFD249" className="w-[5.556vw] sm:w-[0.885vw]">
                      <path fillRule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                            clipRule="evenodd"/>
                    </svg>
                  </div>
                  <div className={'absolute top-[18.889vw] sm:top-[3.958vw] left-[8.889vw] sm:left-[1.563vw] font-[pretendard-sb] text-[5.556vw] sm:text-[1.042vw] leading-none'}>
                    하나로 다둥이가 다 쓸 수 있어요.
                  </div>
                  <div className={'w-[82.222vw] sm:w-[16.875vw] absolute top-[30vw] sm:top-[6.042vw] left-[8.889vw] sm:left-[1.563vw] text-[4.167vw] sm:text-[0.885vw] leading-snug'}>
                    공주 셋 다둥이 저희 집은 항 번 구매로 셋이 다 쓸 수 있어서 좋아요. 선행 학습까지 할 수 있어요!
                  </div>
                  <div className={'absolute top-[46.111vw] sm:top-[9.063vw] left-[8.889vw] sm:left-[1.563vw] text-[4.444vw] sm:text-[0.833vw] leading-none'}>
                    klmb*****님
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={'w-[100vw] sm:w-[20vw] h-[61.111vw] sm:h-[12.083vw] relative bg-[#ffffff] shadow rounded-[5.556vw] sm:rounded-[1.042vw] font-[pretendard-m]'}>
                  <div className={'absolute top-[10vw] sm:top-[2.292vw] left-[8.889vw] sm:left-[1.563vw] flex'}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFD249" className="w-[5.556vw] sm:w-[0.885vw]">
                      <path fillRule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                            clipRule="evenodd"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFD249" className="w-[5.556vw] sm:w-[0.885vw]">
                      <path fillRule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                            clipRule="evenodd"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFD249" className="w-[5.556vw] sm:w-[0.885vw]">
                      <path fillRule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                            clipRule="evenodd"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFD249" className="w-[5.556vw] sm:w-[0.885vw]">
                      <path fillRule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                            clipRule="evenodd"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFD249" className="w-[5.556vw] sm:w-[0.885vw]">
                      <path fillRule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                            clipRule="evenodd"/>
                    </svg>
                  </div>
                  <div className={'absolute top-[18.889vw] sm:top-[3.958vw] left-[8.889vw] sm:left-[1.563vw] font-[pretendard-sb] text-[5.556vw] sm:text-[1.042vw] leading-none'}>
                    연산을 놀이처럼 생각하네요 ㅎㅎ
                  </div>
                  <div className={'w-[82.222vw] sm:w-[16.875vw] absolute top-[30vw] sm:top-[6.042vw] left-[8.889vw] sm:left-[1.563vw] text-[4.167vw] sm:text-[0.885vw] leading-snug'}>
                    아이들이 연산을 놀이처럼 생각하고 접근하네요. 연산을 처음 접하기 좋아요.
                  </div>
                  <div className={'absolute top-[46.111vw] sm:top-[9.063vw] left-[8.889vw] sm:left-[1.563vw] text-[4.444vw] sm:text-[0.833vw] leading-none'}>
                    matr****님
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className={'w-[100vw] sm:w-[20vw] h-[61.111vw] sm:h-[12.083vw] relative bg-[#ffffff] shadow rounded-[5.556vw] sm:rounded-[1.042vw] font-[pretendard-m]'}>
                  <div className={'absolute top-[10vw] sm:top-[2.292vw] left-[8.889vw] sm:left-[1.563vw] flex'}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFD249" className="w-[5.556vw] sm:w-[0.885vw]">
                      <path fillRule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                            clipRule="evenodd"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFD249" className="w-[5.556vw] sm:w-[0.885vw]">
                      <path fillRule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                            clipRule="evenodd"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFD249" className="w-[5.556vw] sm:w-[0.885vw]">
                      <path fillRule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                            clipRule="evenodd"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFD249" className="w-[5.556vw] sm:w-[0.885vw]">
                      <path fillRule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                            clipRule="evenodd"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFD249" className="w-[5.556vw] sm:w-[0.885vw]">
                      <path fillRule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                            clipRule="evenodd"/>
                    </svg>
                  </div>
                  <div className={'absolute top-[18.889vw] sm:top-[3.958vw] left-[8.889vw] sm:left-[1.563vw] font-[pretendard-sb] text-[5.556vw] sm:text-[1.042vw] leading-none'}>
                    아이도 엄마도 서로 스트레스 안 받아요!
                  </div>
                  <div className={'w-[82.222vw] sm:w-[16.875vw] absolute top-[30vw] sm:top-[6.042vw] left-[8.889vw] sm:left-[1.563vw] text-[4.167vw] sm:text-[0.885vw] leading-snug'}>
                    순위가 오르는 게 바로바로 보이니 아이에게 더 좋은 피드백이 되어 좋았어요!
                  </div>
                  <div className={'absolute top-[46.111vw] sm:top-[9.063vw] left-[8.889vw] sm:left-[1.563vw] text-[4.444vw] sm:text-[0.833vw] leading-none'}>
                    hm****님
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
      <div className={'w-full h-[97.778vw] sm:h-[29.531vw] relative'}>
        <div className={'w-full absolute top-[35.556vw] sm:top-[12.5vw] left-[50%] -translate-x-1/2 font-[nanum-square-round-eb]'}>
          <div className={'hidden sm:flex justify-center items-end'}>
            <span className={'text-[3.385vw]'}>일프로연산의&nbsp;</span>
            <span className="flex flex-col items-center">
              <div className="bg-[#5C6DFF] w-[0.625vw] h-[0.625vw] rounded-full"></div>
              <div className={'text-[#2046e4] text-[3.385vw]'}>1</div>
            </span>
            <span className="flex flex-col items-center">
              <div className="bg-[#5C6DFF] w-[0.625vw] h-[0.625vw] rounded-full"></div>
              <div className={'text-[#2046e4] text-[3.385vw]'}>등</div>
            </span>
            <span className={'text-[3.385vw]'}>&nbsp;</span>
            <span className="flex flex-col items-center">
              <div className="bg-[#5C6DFF] w-[0.625vw] h-[0.625vw] rounded-full"></div>
              <div className={'text-[#2046e4] text-[3.385vw]'}>만</div>
            </span>
            <span className="flex flex-col items-center">
              <div className="bg-[#5C6DFF] w-[0.625vw] h-[0.625vw] rounded-full"></div>
              <div className={'text-[#2046e4] text-[3.385vw]'}>들</div>
            </span>
            <span className="flex flex-col items-center">
              <div className="bg-[#5C6DFF] w-[0.625vw] h-[0.625vw] rounded-full"></div>
              <div className={'text-[#2046e4] text-[3.385vw]'}>기</div>
            </span>
            <span className={'text-[3.385vw]'}>&nbsp;비결</span>
          </div>
          <div className={'block sm:hidden'}>
            <div className={'text-[9.444vw] text-center leading-none'}>일프로연산의</div>
            <div className={'flex justify-center items-end leading-tight mt-[2.778vw]'}>
              <span className="flex flex-col items-center">
                <div className="bg-[#5C6DFF] w-[2.222vw] h-[2.222vw] rounded-full"></div>
                <div className={'text-[#2046e4] text-[11.111vw]'}>1</div>
              </span>
              <span className="flex flex-col items-center">
                <div className="bg-[#5C6DFF] w-[2.222vw] h-[2.222vw] rounded-full"></div>
                <div className={'text-[#2046e4] text-[11.111vw]'}>등</div>
              </span>
              <span className={'text-[11.111vw]'}>&nbsp;</span>
              <span className="flex flex-col items-center">
                <div className="bg-[#5C6DFF] w-[2.222vw] h-[2.222vw] rounded-full"></div>
                <div className={'text-[#2046e4] text-[11.111vw]'}>만</div>
              </span>
              <span className="flex flex-col items-center">
                <div className="bg-[#5C6DFF] w-[2.222vw] h-[2.222vw] rounded-full"></div>
                <div className={'text-[#2046e4] text-[11.111vw]'}>들</div>
              </span>
              <span className="flex flex-col items-center">
                <div className="bg-[#5C6DFF] w-[2.222vw] h-[2.222vw] rounded-full"></div>
                <div className={'text-[#2046e4] text-[11.111vw]'}>기</div>
              </span>
              <span className={'text-[11.111vw]'}>&nbsp;비결</span>
            </div>
          </div>
        </div>
      </div>
      <div className={'relative'}>
        <picture className={'relative'}>
          <source media="(min-width: 640px)" srcSet={homeBg6}/>
          <source media="(max-width: 639px)" srcSet={mobileSecretBg}/>
          <img src={homeBg6} alt="home_bg_6" className={'w-full'}/>
        </picture>
        <div className={'w-full h-[374.444vw] sm:h-[86.979vw] absolute top-0'} ref={homeContent7}>
          <CSSTransition in={showHomeContent7} timeout={700} classNames={'home-content-7-1'} mountOnEnter unmountOnExit>
            <div className={'w-full absolute top-[33.333vw] sm:top-[12.917vw]'}>
              <div className={'hidden sm:flex justify-center font-[nanum-square-round-eb]'}>
                <div className={'flex justify-center items-center'}>
                  <div className={'flex justify-center items-center text-[#ffffff] w-[2.5vw] h-[2.5vw] rounded-full bg-[#5C6DFF] text-[1.823vw] leading-none'}>1</div>
                </div>
                <div className={'text-[2.188vw]'}>
                  &nbsp;&nbsp;오늘의 학습으로 기르는 습관과 실력
                </div>
              </div>
              <div className={'block sm:hidden font-[nanum-square-round-eb]'}>
                <div className={'flex justify-center items-center'}>
                  <div className={'flex justify-center items-center text-[#ffffff] w-[13.333vw] h-[13.333vw] rounded-full bg-[#5C6DFF] text-[9.722vw] leading-none'}>1</div>
                </div>
                <div className={'text-[7.778vw] text-center mt-[8.889vw]'}>
                  오늘의 학습으로<br/>기르는 습관과 실력
                </div>
              </div>
            </div>
          </CSSTransition>
          <CSSTransition in={showHomeContent7} timeout={700} classNames={'home-content-7-1'} mountOnEnter unmountOnExit>
            <div className={'w-full absolute top-[82.778vw] sm:top-[17.917vw]'}>
              <div className={'hidden sm:block font-[pretendard-m] text-[1.563vw] text-center leading-none'}>
                자신이 설정한 학습량 대로 매일매일 진행되어 꾸준한 학습 습관을 길러줘요.
              </div>
              <div className={'block sm:hidden font-[pretendard-m] text-[5vw] text-center'}>
                자신이 설정한 학습량 대로 매일매일 진행되어<br/>
                꾸준한 학습 습관을 길러줘요.
              </div>
            </div>
          </CSSTransition>
          <CSSTransition in={showHomeContent7} timeout={900} classNames={'home-content-7-2'} mountOnEnter unmountOnExit>
            <div className={'w-full absolute top-[104.444vw] sm:top-[21.979vw] flex justify-center'}>
              <div className={'w-[88.333vw] sm:w-[60.625vw]'}>
                <ReactPlayer
                  config={{ file: { attributes: { playsInline: true, }, }, }}
                  url={dailylearningVideo}
                  playing={true} // 자동 재생 on
                  muted={true} // 자동 재생 on
                  loop={true}
                  width={'100%'}
                  height={'auto'}
                  style={{ borderRadius: '2.778vw', overflow: 'hidden' }}
                />
              </div>
            </div>
          </CSSTransition>
          <CSSTransition in={showHomeContent7} timeout={1100} classNames={'home-content-7-3'} mountOnEnter unmountOnExit>
            <div className={'absolute top-[164.444vw] sm:top-[60.833vw] left-[28.889vw] sm:left-[22.344vw]'}>
              <div className={'flex justify-center'}>
                <img src={books} alt={'books'} className={'w-[42.222vw] sm:w-[11.875vw]'}/>
              </div>
              <div className={'flex justify-center mt-[8.889vw] sm:mt-[1.667vw] font-[nanum-square-round-eb] text-[7.778vw] sm:text-[1.979vw] leading-none'}>하루 목표 설정</div>
            </div>
          </CSSTransition>
          <CSSTransition in={showHomeContent7} timeout={1100} classNames={'home-content-7-3'} mountOnEnter unmountOnExit>
            <div className={'absolute top-[225.556vw] sm:top-[60.833vw] left-[28.889vw] sm:left-[43.177vw]'}>
              <div className={'flex justify-center'}>
                <img src={repeat} alt={'repeat'} className={'w-[42.222vw] sm:w-[11.875vw]'}/>
              </div>
              <div className={'flex justify-center mt-[8.889vw] sm:mt-[1.667vw] font-[nanum-square-round-eb] text-[7.778vw] sm:text-[1.979vw] leading-none'}>반복 학습</div>
            </div>
          </CSSTransition>
          <CSSTransition in={showHomeContent7} timeout={1100} classNames={'home-content-7-3'} mountOnEnter unmountOnExit>
            <div className={'absolute top-[286.667vw] sm:top-[60.833vw] left-[28.889vw] sm:left-[64.01vw]'}>
              <div className={'flex justify-center'}>
                <img src={routine} alt={'routine'} className={'w-[42.222vw] sm:w-[11.875vw]'}/>
              </div>
              <div className={'flex justify-center mt-[8.889vw] sm:mt-[1.667vw] font-[nanum-square-round-eb] text-[7.778vw] sm:text-[1.979vw] leading-none'}>습관 형성</div>
            </div>
          </CSSTransition>
        </div>
        <div className={'w-full h-[170vw] sm:h-[65.938vw] absolute top-[374.444vw] sm:top-[86.979vw]'} ref={homeContent8}>
          <CSSTransition in={showHomeContent8} timeout={700} classNames={'home-content-8-1'} mountOnEnter unmountOnExit>
            <div>
              <div className={'flex justify-center font-[nanum-square-round-eb] text-[7.778vw] sm:text-[2.188vw] leading-none'}>
                초등 전학년 연산학습
              </div>
              <div className={'hidden sm:flex justify-center font-[nanum-square-round-eb] text-[2.604vw] text-[#2046e4] mt-[1.25vw] leading-none'}>
                총 3000여개의 프리미엄 콘텐츠
              </div>
              <div className={'block sm:hidden font-[nanum-square-round-eb] text-[9.444vw] text-[#2046e4] text-center mt-[2.778vw] leading-snug'}>
                총 3000여개<br/>
                프리미엄 콘텐츠
              </div>
            </div>
          </CSSTransition>
          <CSSTransition in={showHomeContent8} timeout={900} classNames={'home-content-8-2'} mountOnEnter unmountOnExit>
            <div className={'mt-[13.333vw] sm:mt-[3.125vw]'}>
              <Swiper
                modules={[Pagination, Autoplay]}
                slidesPerView={1}
                loop={true}
                pagination={{
                  clickable: true,
                  bulletClass: 'swiper-pagination-bullet custom-bullet-contentbygrade', // 커스텀 클래스 추가
                  bulletActiveClass: 'swiper-pagination-bullet-active custom-bullet-active-contentbygrade', // 커스텀 클래스 추가
                  el: '.custom-swiper-pagination-contentbygrade', // 커스텀 페이지네이션
                  renderBullet: (index, className) => {
                    return `<span class="${className}">${grades[index]}</span>`
                  },
                }}
                // autoplay={{ delay: 4000, disableOnInteraction: false, pauseOnMouseEnter: true }}
              >
                <SwiperSlide style={{ display: 'flex', justifyContent: 'center' }}>
                  <div className={'flex justify-center pt-[37.222vw] sm:pt-[9.375vw]'}>
                    <div className={'w-[88.333vw] sm:w-[60.625vw]'}>
                      <ReactPlayer
                        config={{ file: { attributes: { playsInline: true, }, }, }}
                        url={grade1ContentVideo}
                        playing={true} // 자동 재생 on
                        muted={true} // 자동 재생 on
                        loop={true}
                        width={'100%'}
                        height={'auto'}
                        style={{ borderRadius: '2.778vw', overflow: 'hidden' }}
                      />
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide style={{ display: 'flex', justifyContent: 'center' }}>
                  <div className={'flex justify-center pt-[37.222vw] sm:pt-[9.375vw]'}>
                    <div className={'w-[88.333vw] sm:w-[60.625vw]'}>
                      <ReactPlayer
                        config={{ file: { attributes: { playsInline: true, }, }, }}
                        url={grade2ContentVideo}
                        playing={true} // 자동 재생 on
                        muted={true} // 자동 재생 on
                        loop={true}
                        width={'100%'}
                        height={'auto'}
                        style={{ borderRadius: '2.778vw', overflow: 'hidden' }}
                      />
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide style={{ display: 'flex', justifyContent: 'center' }}>
                  <div className={'flex justify-center pt-[37.222vw] sm:pt-[9.375vw]'}>
                    <div className={'w-[88.333vw] sm:w-[60.625vw]'}>
                      <ReactPlayer
                        config={{ file: { attributes: { playsInline: true, }, }, }}
                        url={grade3ContentVideo}
                        playing={true} // 자동 재생 on
                        muted={true} // 자동 재생 on
                        loop={true}
                        width={'100%'}
                        height={'auto'}
                        style={{ borderRadius: '2.778vw', overflow: 'hidden' }}
                      />
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide style={{ display: 'flex', justifyContent: 'center' }}>
                  <div className={'flex justify-center pt-[37.222vw] sm:pt-[9.375vw]'}>
                    <div className={'w-[88.333vw] sm:w-[60.625vw]'}>
                      <ReactPlayer
                        config={{ file: { attributes: { playsInline: true, }, }, }}
                        url={grade4ContentVideo}
                        playing={true} // 자동 재생 on
                        muted={true} // 자동 재생 on
                        loop={true}
                        width={'100%'}
                        height={'auto'}
                        style={{ borderRadius: '2.778vw', overflow: 'hidden' }}
                      />
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide style={{ display: 'flex', justifyContent: 'center' }}>
                  <div className={'flex justify-center pt-[37.222vw] sm:pt-[9.375vw]'}>
                    <div className={'w-[88.333vw] sm:w-[60.625vw]'}>
                      <ReactPlayer
                        config={{ file: { attributes: { playsInline: true, }, }, }}
                        url={grade5ContentVideo}
                        playing={true} // 자동 재생 on
                        muted={true} // 자동 재생 on
                        loop={true}
                        width={'100%'}
                        height={'auto'}
                        style={{ borderRadius: '2.778vw', overflow: 'hidden' }}
                      />
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide style={{ display: 'flex', justifyContent: 'center' }}>
                  <div className={'flex justify-center pt-[37.222vw] sm:pt-[9.375vw]'}>
                    <div className={'w-[88.333vw] sm:w-[60.625vw]'}>
                      <ReactPlayer
                        config={{ file: { attributes: { playsInline: true, }, }, }}
                        url={grade6ContentVideo}
                        playing={true} // 자동 재생 on
                        muted={true} // 자동 재생 on
                        loop={true}
                        width={'100%'}
                        height={'auto'}
                        style={{ borderRadius: '2.778vw', overflow: 'hidden' }}
                      />
                    </div>
                  </div>
                </SwiperSlide>
                <div className="custom-swiper-pagination-contentbygrade"></div>
              </Swiper>
            </div>
          </CSSTransition>
        </div>
        <div className={'w-full h-[331.111vw] sm:h-[42.083vw] absolute top-[544.444vw] sm:top-[152.917vw]'} ref={homeContent9}>
          <CSSTransition in={showHomeContent9} timeout={700} classNames={'home-content-9-1'} mountOnEnter unmountOnExit>
            <div className={'w-full absolute top-0 font-[nanum-square-round-eb]'}>
              <div className={'hidden sm:flex justify-center items-center'}>
                <div className={'flex justify-center items-center text-[#ffffff] w-[2.5vw] h-[2.5vw] rounded-full bg-[#5C6DFF] text-[1.823vw] leading-none'}>2</div>
                <div className={'text-[2.188vw]'}>
                  &nbsp;&nbsp;스스로 학습을 유도하는 재미요소
                </div>
              </div>
              <div className={'block sm:hidden'}>
                <div className={'flex justify-center items-center'}>
                  <div className={'flex justify-center items-center text-[#ffffff] w-[13.333vw] h-[13.333vw] rounded-full bg-[#5C6DFF] text-[9.722vw] leading-none'}>2</div>
                </div>
                <div className={'text-[7.778vw] text-center mt-[8.889vw]'}>
                  스스로 학습을<br/>유도하는 재미요소
                </div>
              </div>
            </div>
          </CSSTransition>
          <CSSTransition in={showHomeContent9} timeout={900} classNames={'home-content-9-2'} mountOnEnter unmountOnExit>
            <div className={'absolute top-[53.889vw] sm:top-[4.167vw] left-[13.889vw] sm:left-[18.75vw]'}>
              <div className={'flex justify-center'}>
                <picture className={'relative'}>
                  <source media="(min-width: 640px)" srcSet={brain}/>
                  <source media="(max-width: 639px)" srcSet={mobileBrain}/>
                  <img src={brain} alt="brain" className={'w-[44.444vw] sm:w-[20vw]'}/>
                </picture>
              </div>
              <div className={'flex justify-center mt-[5.556vw] sm:mt-[0.833vw] font-[nanum-square-round-eb] text-[7.778vw] sm:text-[1.979vw] leading-none'}>연산 게임</div>
              <div className={'w-[72.222vw] sm:w-auto flex justify-center text-[5vw] sm:text-[1.25vw] font-[pretendard-m] mt-[5.556vw] sm:mt-[0.833vw] text-center'}>
                두뇌를 자극하는 연산게임으로<br/>
                좌뇌는 물론, 우뇌까지 트레이닝해요.
              </div>
            </div>
          </CSSTransition>
          <CSSTransition in={showHomeContent9} timeout={1100} classNames={'home-content-9-3'} mountOnEnter unmountOnExit>
            <div className={'absolute top-[136.667vw] sm:top-[4.167vw] left-[13.889vw] sm:left-[40vw]'}>
              <div className={'flex justify-center'}>
                <picture className={'relative'}>
                  <source media="(min-width: 640px)" srcSet={trophy}/>
                  <source media="(max-width: 639px)" srcSet={mobileTrophy}/>
                  <img src={trophy} alt="trophy" className={'w-[44.444vw] sm:w-[20vw]'}/>
                </picture>
              </div>
              <div className={'flex justify-center mt-[5.556vw] sm:mt-[0.833vw] font-[nanum-square-round-eb] text-[7.778vw] sm:text-[1.979vw] leading-none'}>랭킹&칭호</div>
              <div className={'w-[72.222vw] sm:w-auto flex justify-center text-[5vw] sm:text-[1.25vw] font-[pretendard-m] mt-[5.556vw] sm:mt-[0.833vw] text-center'}>
                학습량에 따라 올라가는 랭킹을 통해<br/>
                성취감을 높이고 칭호로 자랑해요.
              </div>
            </div>
          </CSSTransition>
          <CSSTransition in={showHomeContent9} timeout={1300} classNames={'home-content-9-4'} mountOnEnter unmountOnExit>
            <div className={'absolute top-[219.444vw] sm:top-[4.167vw] left-[13.889vw] sm:left-[61.25vw]'}>
              <div className={'flex justify-center'}>
                <picture className={'relative'}>
                  <source media="(min-width: 640px)" srcSet={cloth}/>
                  <source media="(max-width: 639px)" srcSet={mobileCloth}/>
                  <img src={cloth} alt="cloth" className={'w-[44.444vw] sm:w-[20vw]'}/>
                </picture>
              </div>
              <div className={'flex justify-center mt-[5.556vw] sm:mt-[0.833vw] font-[nanum-square-round-eb] text-[7.778vw] sm:text-[1.979vw] leading-none'}>나만의 아바타</div>
              <div className={'w-[72.222vw] sm:w-auto flex justify-center text-[5vw] sm:text-[1.25vw] font-[pretendard-m] mt-[5.556vw] sm:mt-[0.833vw] text-center'}>
                학습을 진행하며 받은 보상으로<br/>
                귀여운 나만의 아바타를 꾸며요.
              </div>
            </div>
          </CSSTransition>
        </div>
        <div className={'w-full absolute top-[875.556vw] sm:top-[195vw]'}>
          <div>
            <Swiper
              ref={infiniteRollingBanner4Ref}
              modules={[Autoplay]}
              loop={true}
              spaceBetween={24} // 기본값을 24px로 설정
              slidesPerView={1}
              speed={5000} // 슬라이드 전환 속도
              autoplay={{
                delay: 0, // 자동 전환 간 지연 시간
                disableOnInteraction: false,
                pauseOnMouseEnter: true, // 마우스 호버 시 일시 정지
              }}
              breakpoints={{
                // 화면의 너비가 640px 이상일 때
                640: {
                  slidesPerView: 5, // 640px 이상일 때 슬라이드 5개 보이기
                }
              }}
            >
              <SwiperSlide>
                <img src={home121} alt={'home_12_1'} className={'w-[94.444vw] sm:w-[20vw]'}/>
              </SwiperSlide>
              <SwiperSlide>
                <img src={home122} alt={'home_12_2'} className={'w-[94.444vw] sm:w-[20vw]'}/>
              </SwiperSlide>
              <SwiperSlide>
                <img src={home123} alt={'home_12_3'} className={'w-[94.444vw] sm:w-[20vw]'}/>
              </SwiperSlide>
              <SwiperSlide>
                <img src={home124} alt={'home_12_4'} className={'w-[94.444vw] sm:w-[20vw]'}/>
              </SwiperSlide>
              <SwiperSlide>
                <img src={home125} alt={'home_12_5'} className={'w-[94.444vw] sm:w-[20vw]'}/>
              </SwiperSlide>
              <SwiperSlide>
                <img src={home126} alt={'home_12_6'} className={'w-[94.444vw] sm:w-[20vw]'}/>
              </SwiperSlide>
            </Swiper>
          </div>
          <div className={'mt-[6.667vw] sm:mt-[1.667vw]'}>
            <Swiper
              ref={infiniteRollingBanner5Ref}
              modules={[Autoplay]}
              loop={true}
              spaceBetween={24} // 기본값을 24px로 설정
              slidesPerView={1}
              speed={5100} // 슬라이드 전환 속도
              autoplay={{
                delay: 0, // 자동 전환 간 지연 시간
                disableOnInteraction: false,
                reverseDirection: true,
                pauseOnMouseEnter: true, // 마우스 호버 시 일시 정지
              }}
              breakpoints={{
                // 화면의 너비가 640px 이상일 때
                640: {
                  slidesPerView: 5, // 640px 이상일 때 슬라이드 4개 보이기
                }
              }}
            >
              <SwiperSlide>
                <img src={home131} alt={'home_13_1'} className={'w-[94.444vw] sm:w-[20vw]'}/>
              </SwiperSlide>
              <SwiperSlide>
                <img src={home132} alt={'home_13_2'} className={'w-[94.444vw] sm:w-[20vw]'}/>
              </SwiperSlide>
              <SwiperSlide>
                <img src={home133} alt={'home_13_3'} className={'w-[94.444vw] sm:w-[20vw]'}/>
              </SwiperSlide>
              <SwiperSlide>
                <img src={home134} alt={'home_13_4'} className={'w-[94.444vw] sm:w-[20vw]'}/>
              </SwiperSlide>
              <SwiperSlide>
                <img src={home135} alt={'home_13_5'} className={'w-[94.444vw] sm:w-[20vw]'}/>
              </SwiperSlide>
              <SwiperSlide>
                <img src={home136} alt={'home_13_6'} className={'w-[94.444vw] sm:w-[20vw]'}/>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
        <div className={'absolute top-[1020.278vw] sm:top-[221.927vw] left-[50%] -translate-x-1/2'}>
          <div
            onClick={() => {
              navigate(`/content`)
            }}
            className={'flex justify-center items-center w-[72.222vw] sm:w-[18.125vw] h-[20vw] sm:h-[5.625vw] border-[0.556vw] sm:border-[0.156vw] border-[#2046E4] rounded-[4.167vw] sm:rounded-[1.042vw] text-[6.667vw] sm:text-[1.823vw] text-[#2046e4] font-[nanum-square-round-eb] leading-none cursor-pointer'}>
            콘텐츠 둘러보기
          </div>
        </div>
      </div>
      <div className={'w-full h-[306.389vw] sm:h-[78.75vw] relative'} ref={homeContent10}>
        <CSSTransition in={showHomeContent10} timeout={700} classNames={'home-content-10-1'} mountOnEnter unmountOnExit>
          <div className={'w-full absolute top-[35.556vw] sm:top-[12.604vw]'}>
            <div className={'hidden sm:flex justify-center font-[nanum-square-round-eb]'}>
              <div className={'flex justify-center items-center'}>
                <div className={'flex justify-center items-center text-[#ffffff] w-[2.5vw] h-[2.5vw] rounded-full bg-[#5C6DFF] text-[1.823vw] leading-none'}>3</div>
              </div>
              <div className={'text-[2.188vw]'}>
                &nbsp;&nbsp;1등 엄마의 학습관리
              </div>
            </div>
            <div className={'block sm:hidden font-[nanum-square-round-eb]'}>
              <div className={'flex justify-center items-center'}>
                <div className={'flex justify-center items-center text-[#ffffff] w-[13.333vw] h-[13.333vw] rounded-full bg-[#5C6DFF] text-[9.722vw] leading-none'}>3</div>
              </div>
              <div className={'text-[7.778vw] text-center mt-[8.889vw]'}>
                1등 엄마의 학습관리
              </div>
            </div>
          </div>
        </CSSTransition>
        <CSSTransition in={showHomeContent10} timeout={700} classNames={'home-content-10-1'} mountOnEnter unmountOnExit>
          <div className={'w-full absolute top-[78.889vw] sm:top-[17.604vw]'}>
            <div className={'hidden sm:flex justify-center font-[pretendard-m] text-[1.563vw] leading-none'}>
              채점부터 취약부분 분석까지 학습현황을 손쉽게 관리해요!
            </div>
            <div className={'block sm:hidden font-[pretendard-m] text-[5vw] text-center'}>
              채점부터 취약부분 분석까지<br/>
              학습 현황을 손쉽게 관리하세요!
            </div>
          </div>
        </CSSTransition>
        <div className={'w-full absolute top-[100.556vw] sm:top-[21.667vw]'} ref={homeContent11}>
          <picture className={'relative flex justify-center'}>
            <source media="(min-width: 640px)" srcSet={homeBg8}/>
            <source media="(max-width: 639px)" srcSet={mobileManageBg}/>
            <img src={homeBg8} alt="home_bg_8" className={'w-[91.111vw] sm:w-full'}/>
          </picture>
          <CSSTransition in={showHomeContent11} timeout={700} classNames={'home-content-11-1'} mountOnEnter unmountOnExit>
            <div className={'w-full absolute top-[6.944vw] sm:top-[3.229vw]'}>
              <div className={'flex justify-center'}>
                <div
                  className={'flex justify-center items-center border-[0.278vw] sm:border-[0.104vw] border-[#ffffff] rounded-full w-[25.278vw] sm:w-[9.063vw] h-[8.889vw] sm:h-[3.229vw] font-[nanum-square-round-eb] text-[4.444vw] sm:text-[1.563vw] text-[#ffffff]'}>오답노트
                </div>
                <div
                  className={'flex justify-center items-center border-[0.278vw] sm:border-[0.104vw] border-[#ffffff] rounded-full w-[25.278vw] sm:w-[9.063vw] h-[8.889vw] sm:h-[3.229vw] font-[nanum-square-round-eb] text-[4.444vw] sm:text-[1.563vw] text-[#ffffff] ml-[1.667vw] sm:ml-[1.25vw]'}>자동채점
                </div>
                <div
                  className={'hidden sm:flex justify-center items-center border-[0.104vw] border-[#ffffff] rounded-full w-[12.292vw] h-[3.229vw] font-[nanum-square-round-eb] text-[1.563vw] text-[#ffffff] ml-[1.25vw]'}>학습결과
                  분석
                </div>
                <div
                  className={'flex sm:hidden justify-center items-center border-[0.278vw] border-[#ffffff] rounded-full w-[26.389vw] h-[8.889vw] font-[nanum-square-round-eb] text-[4.444vw] text-[#ffffff] ml-[1.667vw]'}>
                  결과 분석
                </div>
              </div>
              <div className={'flex justify-center mt-[4.444vw] sm:mt-[1.042vw]'}>
                <div
                  className={'flex justify-center items-center border-[0.278vw] sm:border-[0.104vw] border-[#ffffff] rounded-full w-[36.389vw] sm:w-[12.969vw] h-[8.889vw] sm:h-[3.229vw] font-[nanum-square-round-eb] text-[4.444vw] sm:text-[1.563vw] text-[#ffffff]'}>우수/취약
                  분석
                </div>
                <div
                  className={'flex justify-center items-center border-[0.278vw] sm:border-[0.104vw] border-[#ffffff] rounded-full w-[43.333vw] sm:w-[15.469vw] h-[8.889vw] sm:h-[3.229vw] font-[nanum-square-round-eb] text-[4.444vw] sm:text-[1.563vw] text-[#ffffff] ml-[1.667vw] sm:ml-[1.25vw]'}>일/주/월
                  별 리포트
                </div>
              </div>
            </div>
          </CSSTransition>
          <div className={'hidden sm:block'}>
            <CSSTransition in={showHomeContent11} timeout={900} classNames={'home-content-11-2'} mountOnEnter unmountOnExit>
              <img src={home141} alt={'home14_1'} className={'w-[25.625vw] absolute bottom-0 left-[25.313vw]'}/>
            </CSSTransition>
            <CSSTransition in={showHomeContent11} timeout={900} classNames={'home-content-11-3'} mountOnEnter unmountOnExit>
              <img src={home142} alt={'home14_2'} className={'w-[25.625vw] absolute bottom-0 left-[49.01vw]'}/>
            </CSSTransition>
          </div>
          <div className={'block sm:hidden'}>
            <CSSTransition in={showHomeContent11} timeout={900} classNames={'home-content-11-4'} mountOnEnter unmountOnExit>
              <div>
                <div className={'w-full absolute bottom-[65.556vw] flex justify-center'}>
                  <img src={mobileAppIcons} alt={'mobile_app_icons'} className={'w-[35vw]'}/>
                </div>
                <div className={'w-full absolute bottom-0 flex justify-center'}>
                  <img src={mobileOneproParentsApp} alt={'mobile_oneproparents_app'} className={'w-[75vw]'}/>
                </div>
              </div>
            </CSSTransition>
          </div>
        </div>
        <div
          onClick={() => {
            navigate(`/content/content5`)
          }}
          className={'absolute top-[250.556vw] sm:top-[60.625vw] left-[50%] -translate-x-1/2 flex justify-center items-center w-[72.222vw] sm:w-[18.125vw] h-[20vw] sm:h-[5.625vw] border-[0.556vw] sm:border-[0.156vw] border-[#2046E4] rounded-[4.167vw] sm:rounded-[1.042vw] text-[6.667vw] sm:text-[1.823vw] text-[#2046e4] font-[nanum-square-round-eb] leading-none cursor-pointer'}>
          콘텐츠 둘러보기
        </div>
      </div>
      <div className={'relative'} ref={homeContent12}>
        <picture className={'relative'}>
          <source media="(min-width: 640px)" srcSet={homeBg9}/>
          <source media="(max-width: 639px)" srcSet={mobileHomeBg9}/>
          <img src={homeBg9} alt="home_bg_9" className={'w-full'}/>
        </picture>
        <div className={'absolute top-0 w-full'}>
          <CSSTransition in={showHomeContent12} timeout={700} classNames={'home-content-12-1'} mountOnEnter unmountOnExit>
            <div>
              <div className={'flex justify-center mt-[26.944vw] sm:mt-[3.125vw]'}>
                <Logo className={'w-[22.222vw] sm:w-[7.083vw] h-[8.169vw] sm:h-[2.604vw]'}/>
              </div>
              <div className={'flex justify-center mt-[5.278vw] sm:mt-[1.875vw] font-[nanum-square-round-eb] text-[9.722vw] sm:text-[2.813vw] leading-none'}>
                1등 엄마 되기
              </div>
              <div className={'relative mt-[5.278vw] sm:mt-[1.25vw]'}>
                <div className={'relative flex justify-center z-[9] font-[nanum-square-round-eb] text-[12.778vw] sm:text-[4.479vw] leading-none'}>
                  이렇게 쉬워요!
                </div>
                <div className={'absolute bottom-0 flex justify-center w-full'}>
                  <img src={home15} alt={'home_15'} className={'w-[76.667vw] sm:w-[28.125vw]'}/>
                </div>
              </div>
            </div>
          </CSSTransition>
          <CSSTransition in={showHomeContent12} timeout={900} classNames={'home-content-12-2'} mountOnEnter unmountOnExit>
            <picture className={'w-full absolute top-[70.833vw] sm:top-[16.719vw] flex justify-center'}>
              <source media="(min-width: 640px)" srcSet={home16}/>
              <source media="(max-width: 639px)" srcSet={mobileMomNChild}/>
              <img src={home16} alt="home_16" className={'w-full sm:w-[41.458vw]'}/>
            </picture>
          </CSSTransition>
        </div>
      </div>
      <div className={'h-[103.611vw] sm:h-[19.583vw]'}>
        <CSSTransition in={showHomeContent12} timeout={1100} classNames={'home-content-12-3'} mountOnEnter unmountOnExit>
          <div className={'w-full relative font-[nanum-square-round-eb] text-[6.667vw] sm:text-[2.083vw]'}>
            <a
              target={'_blank'}
              href={'http://1promath.co.kr/pages/store/onepromath.php'}
              className={'absolute top-[13.333vw] sm:top-0 left-[13.889vw] sm:left-[22.969vw] flex justify-center items-center w-[72.222vw] sm:w-[25vw] h-[20vw] sm:h-[7.083vw] bg-[#2046E4] rounded-[4.167vw] sm:rounded-[1.563vw] text-[#FFFA7E]'}>
              3일 무료체험
            </a>
            <a
              target={'_blank'}
              href={'https://smartstore.naver.com/1promath'}
              className={'absolute top-[40vw] sm:top-0 left-[13.889vw] sm:left-[51.094vw] flex justify-center items-center w-[72.222vw] sm:w-[25vw] h-[20vw] sm:h-[7.083vw] bg-[#2046E4] rounded-[4.167vw] sm:rounded-[1.563vw] text-[#ffffff]'}>
              구매하러 가기
            </a>
          </div>
        </CSSTransition>
      </div>
      <Footer/>
    </div>
  )
}

export default Home
